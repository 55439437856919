import React, { Component } from 'react';
import { addNewPlaylist, getAuthorList } from './action';
import { Loader } from '../../utils/common';
import { Row, Col, Modal, Button, ModalHeader, ModalBody, Card, FormGroup, Label, Input, CardBody, CardFooter, Form, Alert } from "reactstrap";
import { AppSwitch } from '@coreui/react'
import SimpleReactValidator from 'simple-react-validator';
import Constants from '../../config/constants';




import * as _ from 'underscore'
import { faTruckMonster } from '@fortawesome/free-solid-svg-icons';

class AddMediaSeries extends Component {


  constructor(props) {
    super(props);
    this.validator = new SimpleReactValidator();
    this.state = {
      title: "",
      status: true,
      forGuest: false,
      isFree: false,
      mediaSeries: faTruckMonster,
      screenError: "",
      authorsList: [],
      author: null
    }

  }


  componentDidMount() {
    this.getAuthorlisFunct()
  }

  getAuthorlisFunct(){
    getAuthorList().then(getAuthorListResp => {
      console.log('getAuthorListResp',getAuthorListResp);
      if(getAuthorListResp && getAuthorListResp.result && getAuthorListResp.result.length){
        let tempStatObj = this.state;
        tempStatObj.authorsList = getAuthorListResp.result
        this.setState(tempStatObj)
      }
    })
  }


  componentWillReceiveProps(nextProps) {

  }

  handleChange(event) {
    let target = event.target;

    let value;

    if (target.type === 'checkbox' && target.name === 'status') {
      value = (target.value === 'Active') ? false : true;
    } else if (target.type === 'checkbox' && target.name === 'forGuest') {
      value = (target.value === 'Active') ? false : true;
    } else if (target.type === 'checkbox' && target.name === 'isFree') {
      value = (target.value === 'Active') ? false : true;
    } else {
      value = target.value;
    }

    const name = target.name;
    this.setState({ [name]: value });
  }

  handleAuthorChange(event) {
    if(event && event.target && event.target.value){
      this.setState({author: event.target.value})
    }
  }



  handleSubmit(event) {
    this.setState({ screenError: "" });
    if (this.validator.allValid()) {
      addNewPlaylist({ title: this.state.title, status: this.state.status, forGuest: this.state.forGuest, isFree: this.state.isFree, mediaSeries: true, authorId: this.state.author }).then(repsonse => {
        this.props.refreshPlaylist();
      }, error => {
        this.setState({
          screenError: error.message
        })
      })
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
    event.preventDefault();
  }

  render() {

    let { visible, closeModal } = this.props;
    let { screenError } = this.state;

    return (
      <Modal isOpen={visible}>
        <ModalHeader toggle={() => { closeModal() }}>Add Media / Series</ModalHeader>
        <ModalBody>
          <Form onSubmit={(event) => { this.handleSubmit(event) }}>
            <Row>
              <Col sm="12">
                <Card>
                  <CardBody>
                    <Row>
                      <Col xs="12">
                        <FormGroup>
                          <Label htmlFor="title">Title</Label>
                          <Input type="text" name="title" id="title" placeholder="Title" value={this.state.title} onChange={($event) => { this.handleChange($event) }} />
                          <div className="validation-error">{this.validator.message('title', this.state.title, 'required')}</div>
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row>
                      <Col xs="12">
                        <FormGroup>
                          <Label htmlFor="status">Status</Label>
                          <div>
                            <AppSwitch className={'mx-1'} variant={'pill'} color={'primary'} id="status" name="status"
                              onChange={($event) => { this.handleChange($event) }}
                              checked={this.state.status}
                              value={this.state.status ? 'Active' : 'Inactive'} />
                          </div>
                        </FormGroup>
                      </Col>
                    </Row>


                    <Row>
                      <Col xs="12">
                        <FormGroup>
                          <Label htmlFor="forGuest">For Guest User ?</Label>
                          <div>
                            <AppSwitch className={'mx-1'} variant={'pill'} color={'primary'} id="forGuest" name="forGuest"
                              onChange={($event) => { this.handleChange($event) }}
                              checked={this.state.forGuest}
                              value={this.state.forGuest ? 'Active' : 'Inactive'} />
                          </div>
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row>
                      <Col xs="12">
                        <FormGroup>
                          <Label htmlFor="isFree">Free All Content</Label>
                          <div>
                            <AppSwitch className={'mx-1'} variant={'pill'} color={'primary'} id="isFree" name="isFree"
                              onChange={($event) => { this.handleChange($event) }}
                              checked={this.state.isFree}
                              value={this.state.isFree ? 'Active' : 'Inactive'} />
                          </div>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                    <Col xs="12">
                            <FormGroup>
                              <Input type="select" name="authorName" id="authorName" value={this.state.author} onChange={($event) => { this.handleAuthorChange($event) }}>
                                <option value="">Select Writer</option>
                                {this.state.authorsList.length && this.state.authorsList.map(obj => <option key={obj._id} data-id={obj._id} value={obj['_id']} >{obj.authorName}</option>)}
                              </Input>
                              <div className="validation-error">{this.validator.message('Writer', this.state.author, 'required')}</div>
                            </FormGroup>
                          </Col>
                    </Row>

                    {screenError && <Alert color="danger">
                      {screenError}
                    </Alert>}


                  </CardBody>
                  <CardFooter>
                    <Button type="submit" size="sm" color="primary">Save</Button>
                    <Button type="reset" size="sm" color="danger" onClick={() => { closeModal() }}>Cancel</Button>
                  </CardFooter>
                </Card>
              </Col>
            </Row>
          </Form>
        </ModalBody>
      </Modal>
    );
  }
}



export default AddMediaSeries;
