import React, { Component } from 'react';
import { getPlaylistContent, getContentList, addContentInPlaylist, removeTrackFromPlayList } from './action';
import { Loader } from '../../utils/common';
import { Modal, Button, ModalHeader, ModalBody, ModalFooter, Table, Row, Col, Input, Alert } from "reactstrap";
import { AppSwitch } from '@coreui/react'
import SimpleReactValidator from 'simple-react-validator';
import Constants from '../../config/constants';


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash } from '@fortawesome/free-solid-svg-icons'
import Autocomplete from 'react-autocomplete'

import moment from 'moment';
import { pluck, contains, findWhere, unique } from 'underscore';

class TrackDetails extends Component {


  constructor(props) {
    super(props);
    this.validator = new SimpleReactValidator();
    this.intervalInstance = null;
    this.state = {
      screenError: "",
      myScreenError: "",
      selectedObj: props.selectedObj,
      contentTitle: "",
      contentSearchName: "",
      contentList: [],
      suggestedContentList: [],
      totalRecords: 0,
      selectedContentArr: [],
      openDeleteConfirmModel: false,
      mySelectedObj: null
    }
  }

  componentDidMount() {
    this.getPlaylistContent();
    // this.getContentList();
  }

  getSuggestedContent() {
    Loader(true)
    getContentList({ limit: 10, search: this.state.contentSearchName, type: 'playlist' }).then(contentList => {
      if (contentList && contentList.statusCode == 200) {
        this.setState({
          suggestedContentList: contentList.result
        })
      }
      Loader(false)
    }, error => {
      this.setState({
        screenError: (error && error.data && error.data.statusDescription) ? error.data.statusDescription : Constants.SOMETHING_WRONG
      })
      Loader(false)
    })
  }


  getPlaylistContent() {
    Loader(true);
    getPlaylistContent(this.state.selectedObj._id).then(response => {
      if (response && response.statusCode == 200) {
        this.setState({
          totalRecords: response.totalRecords,
          contentList: response.result,
          selectedContentArr: pluck(response.result, "title")
        })
      } else {
        this.setState({
          screenError: "No Data found"
        })
      }
      Loader(false);
    }, error => {
      this.setState({
        screenError: (error && error.data && error.data.statusDescription) ? error.data.statusDescription : Constants.SOMETHING_WRONG
      })
    })
  }

  getContents(e) {
    this.setState({ contentSearchName: e.target.value })

    clearTimeout(this.intervalInstance)
    this.intervalInstance = setTimeout(() => {
      this.getSuggestedContent()
    }, 1000);
  }

  addContentInPlayList(val) {
    if (contains(this.state.selectedContentArr, val)) {
      this.setState({ screenError: "Already added" });
      return;
    } else {
      // getting id of selected content
      let selectedContent = findWhere(this.state.suggestedContentList, { title: val });
      if (selectedContent) {
        this.state.selectedContentArr.push(selectedContent.title);
        this.state.selectedContentArr = unique(this.state.selectedContentArr);
        this.setState({ screenError: "", selectedContentArr: this.state.selectedContentArr });

        // adding this content in playlist
        let params = {
          categoryId: selectedContent.categoryId,
          contentId: selectedContent._id,
          playlistId: this.state.selectedObj._id,
        }
        addContentInPlaylist(params).then(() => {
          this.getPlaylistContent();
        }, error => {
          this.setState({
            screenError: (error && error.data && error.data.statusDescription) ? error.data.statusDescription : Constants.SOMETHING_WRONG
          })
        })
      }

    }
  }


  deleteContentFromPlaylist(obj) {
    this.setState({
      openDeleteConfirmModel: true,
      mySelectedObj: obj
    })
  }

  closeMyModel() {
    this.setState({
      openDeleteConfirmModel: false,
      mySelectedObj: null
    })
  }

  deleteTrackFromPlaylist() {
    Loader(true)

    removeTrackFromPlayList({
      playlistId: this.state.selectedObj._id,
      playlistContentId: this.state.mySelectedObj.playlistContentId
    }).then(() => {
      this.getPlaylistContent()
      this.closeMyModel();
      Loader(false)
    }, error => {
      this.setState({
        myScreenError: (error && error.data && error.data.statusDescription) ? error.data.statusDescription : Constants.SOMETHING_WRONG
      })
      Loader(false)
    })
  }

  render() {

    let { visible, closeModal } = this.props;
    let { selectedObj, screenError, contentList, totalRecords, contentSearchName, suggestedContentList, mySelectedObj, openDeleteConfirmModel, myScreenError } = this.state;

    return (

      <React.Fragment>
        <Modal isOpen={visible} size="lg">
          <ModalHeader toggle={() => { closeModal() }}>Playlist {selectedObj.title}'s content list</ModalHeader>
          <ModalBody>
            <Row className="pb-4">

              <Col lg={3}>
                {/* <Input autoComplete="off" type="text" id="contentTitle" placeholder="Type Content title and press enter" name="contentTitle" value={this.state.contentTitle}
                onChange={($event) => { this.setState({ searchKey: $event.target.value }); this.searchPlaylist() }} /> */}

                <Autocomplete
                  inputProps={{ placeholder: "Type Content title and press enter", className: "form-control" }}
                  placeholder="Type Content title and press enter"
                  menuStyle={{
                    borderRadius: '5px',
                    boxShadow: '0 2px 12px rgba(0, 0, 0, 0.1)',
                    background: 'rgba(255, 255, 255, 0.9)',
                    padding: '2px 0',
                    fontSize: '90%',
                    position: 'fixed',
                    overflow: 'auto',
                    maxHeight: '50%',
                    zIndex: '1',
                    padding: 10,
                    fontSize: 15
                  }}
                  value={contentSearchName}
                  getItemValue={(item) => item.title}
                  items={suggestedContentList}
                  onChange={(e) => this.getContents(e)}
                  onSelect={(val) => this.addContentInPlayList(val)}
                  renderItem={(item, isHighlighted) =>
                    <div style={{ background: isHighlighted ? 'lightgray' : 'white' }}>
                      {item.title}
                    </div>

                  }
                // onChange={(e) => value = e.target.value}
                // onSelect={(val) => value = val}
                />
              </Col>

              {/* <Col lg={3}>
              <button onClick={() => { this.addPlaylist() }} className="btn btn-primary">Add</button>
            </Col> */}
            </Row>

            <Row>
              <Col lg={12}>
                {screenError && <Alert color="danger">
                  {screenError}
                </Alert>}

                <Table bordered>
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Title</th>
                      <th>Tag</th>
                      <th>Content Type</th>
                      <th>Naratted By</th>
                      <th>Added On</th>
                      <th>Remove</th>
                    </tr>
                  </thead>
                  <tbody>
                    {contentList && contentList.length ?

                      contentList.map((obj, index) => {
                        return (
                          <tr key={index}>
                            <th scope="row">{index + 1}</th>
                            <td>
                              {obj.title}
                            </td>
                            <td>
                              {(obj.tags && obj.tags.length) ? obj.tags.join() : "FREE"}
                            </td>
                            <td>
                              {obj.contentType}
                            </td>
                            <td>
                              {obj.narratedBy}
                            </td>
                            <td>
                              {moment(obj.createdDate).format("YYYY-MMM-DD")}
                            </td>
                            <td>
                              <a className="" href="#" title="Delete Content from this playlist" onClick={() => this.deleteContentFromPlaylist(obj)}><FontAwesomeIcon icon={faTrash} size='sm' /></a>
                            </td>

                          </tr>
                        )
                      }) :
                      <tr>
                        <td colSpan="7" className="no-record-label">No Records </td>
                      </tr>
                    }
                  </tbody>
                </Table>
                <div>Total Records : {totalRecords}</div>
              </Col>
            </Row>
          </ModalBody>
        </Modal>
        {mySelectedObj && openDeleteConfirmModel ?
          <Modal isOpen={openDeleteConfirmModel}>
            <ModalHeader toggle={() => { this.closeMyModel() }}>Delete Playlist</ModalHeader>
            <ModalBody>
              Are you sure, want to delete this track from playlist ?
            </ModalBody>
            {myScreenError && <Alert color="danger">
              {myScreenError}
            </Alert>}
            <ModalFooter className="justify-content-left">
              <Button type="button" size="sm" color="primary" onClick={() => { this.deleteTrackFromPlaylist() }}>Delete</Button>{' '}
              <Button type="reset" size="sm" color="danger" onClick={() => { this.closeMyModel() }}>Cancel</Button>
            </ModalFooter>
          </Modal>
          : null
        }
      </React.Fragment>
    );
  }
}



export default TrackDetails;
