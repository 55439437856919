import React, { Component } from 'react';
import { createRandomStr, Loader } from '../../utils/common';
import { Row, Col, Modal, Button, ModalHeader, ModalBody, ModalFooter, FormGroup, Label, Input, Form, Alert } from "reactstrap";
import { AppSwitch } from '@coreui/react'
import SimpleReactValidator from 'simple-react-validator';
import * as _ from 'underscore';
import httpClient from '../../services/http';
import S3Client from '../../services/aws-s3/S3Client';
import each from 'async/each';


class AddTrophy extends Component {
  constructor(props) {
    super(props);
    this.validator = new SimpleReactValidator();
    this.state = {
      trophiesCount : 1,
      customValidator : [],
      
      trophies : [],
      pillarData : [],

      type : "",
      color : "#000000",
      trophyImage : "",
      image : "",
      customValidatorMsg:{ typeImage:"" },
      status : true,

      selectedPillar : {},
      typedContent : "",
      shouldSubmit : false,
      systemLanguages : props.systemLanguages,
      trophyTypes : [],
      typeImage: null,
      trophyPlaceholderImage: null,
      trophyImageError: [{image: null, pillarImage: null}],
      groupImage :"",
      validatedImgs : [],
      typeImage : [],
    }
    this.selectedImages = [];
    const s3Config = {
        bucketName: process.env.REACT_APP_S3_BUCKET,
        dirName: 'points/trophy',
        region: process.env.REACT_APP_AWS_DEFAULT_REGION,
        accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
        s3Url: process.env.REACT_APP_AWS_BUCKET_URL, /* optional */
    }
    this.S3Client = new S3Client(s3Config);
  }

  componentDidMount() {
    let self = this;
    self.setTrophyAttributes();
  }

  setTrophyAttributes() {
    let self = this;
    _.each(this.state.systemLanguages, function(value, index){
      self.state.systemLanguages[index]['trophyLang'] = {title : "", info : "", trophyDescription: "", languageSlug :  value['slug']};
      self.state.trophyTypes.push({
        type : "",
        title : value['title'],
        languageSlug : value['slug']
      })
    });

    self.state.trophies.push({
      defaultPoints: 1,
      systemLanguages : JSON.parse(JSON.stringify(self.state.systemLanguages)),
      image: "",
      pillarImage: "",
      trophyPlaceholderImage: "",
      imageDisplay: "",
      pillarImageDisplay: "",
      trophyPlaceholderImageDisplay: "",
    });

    self.setState({systemLanguages : self.state.systemLanguages, trophies : self.state.trophies});


  }

  componentWillReceiveProps(nextProps) {
    let self = this;
  }


  

  handleChange(event) {
    let target = event.target;
    let name = target.name;
    let value;
    value = target.value;

    if (target.type === 'checkbox') {
      value = event.currentTarget.checked
    }
    this.setState({[name]: value});
  }

  handleSubmit(e) {
    let validatedImage =  this.validateImageType('image', this.state['image']);
    let trophyValidated = this.validateImageType('pillarImage', this.state['pillarImage']);
    let trophyPlaceholderImageValidated = this.validateImageType('trophyPlaceholderImage', this.state['trophyPlaceholderImage']);
    let {trophies, typeImage, trophyPlaceholderImage} = this.state;

    if( this.validator.allValid() && validatedImage && trophyValidated && trophyPlaceholderImageValidated &&  !_.contains(this.state.validatedImgs, false) && this.state.groupImage){
      Loader(true)
      this.uploadImage(trophies).then((uploadedImage)=>{
        if(uploadedImage){
          uploadedImage.map((element, key)=>{
              if(element.type === 'list'){
                trophies[element.index][element.name] = element.path;
              }else if(element.type === 'group'){
                typeImage = element.path;
              }
          });
        }


        let trophiesObj = []
        _.each(trophies, function(value, index){
          trophiesObj.push({
            defaultPoints :   value["defaultPoints"],
            trophyLang :   _.pluck(value['systemLanguages'], 'trophyLang'),
            image:   value['image'],
            pillarImage: value['pillarImage'],
            trophyPlaceholderImage: value['trophyPlaceholderImage'],
          })
        })

        let params = {
          trophyData: trophiesObj,
          status: this.state.status.toString(),
          trophyTypes: this.state.trophyTypes,
          pillarData: this.state.pillarData,
          typeImage: typeImage,
          color: this.state.color
        }
        this.props.addTrophy(e, params);
        Loader(false)
      })

    }else{
      this.validator.showMessages();
      this.forceUpdate();
    }

    e.preventDefault();
  }


  uploadImage(){
    const selectedImages = this.selectedImages;
    const self = this;
    return new Promise((resolve, reject)=>{

      self.S3Client.getPolicy().then((policyRes)=>{
        httpClient.call('PILLAR', '/get_signature', {policy: policyRes}, {method: 'GET', headers: {}}).then((signatureRes)=>{
          if(signatureRes && signatureRes.statusCode === 200 && signatureRes.result && signatureRes.result.signature){
              let uploadedImageObj = [];
              each(selectedImages, function(imageObj, callback) {
                if(imageObj.file instanceof File){
                  createRandomStr((randString)=>{ 
                    let signature = signatureRes.result.signature;
                    let filename = imageObj.folder+'/'+randString;
                    let ext = imageObj.file.name.split('.')[1];
                    self.S3Client.uploadFile(imageObj.file, filename+'.'+ext, signature).then(data => {
                      uploadedImageObj.push({index: imageObj.index, name: imageObj.name, path: data.key, type: imageObj.type});
                      callback();
                    }).catch(err => {
                      err = err;
                      console.log(err);
                      return callback(null, err); 
                    })
                  });
                }else{
                  callback()
                }
              }, function(err) {
                // if any of the file processing produced an error, err would equal that error
                if( err ) {
                  // One of the iterations produced an error.
                  // All processing will now stop.
                  console.log('A file failed to process', err);
                  reject(err);
                } else {
                  console.log('All files have been processed successfully');
                  resolve(uploadedImageObj);                  
                }
            });
          }else{
            console.log('ERROR:- SIGNATURE RESPONSE ', signatureRes);
            reject(signatureRes.statusDescription);
          }
        }, (error) => {
          console.log('ERROR:- SIGNATURE REQUEST ', error);
          reject(error);
        })
      }, (error) => {
        console.log('ERROR:- SIGNATURE REQUEST ', error);
        reject(error);
      });
    })
  }

  formatTitlePosLabel (s) {
    if (typeof s !== 'string') return ''
      s = s.replace("_", " ");
      s = s.charAt(0).toUpperCase() + s.slice(1).toLowerCase();
      return s;
  }

  setImg(event) {
    let self = this;
    var file = event.target.files[0];
    this.setState({[event.target.name] : file});
    this.validateImageType(event.target.name, file)

    if (this.validateImageType(event.target.name, file) && event.target.files && event.target.files[0]) {
      this.selectedImages.push({index: 0, name: event.target.name, file: file, folder: 'type', type: 'group'});
      self.setState({groupImage: URL.createObjectURL(event.target.files[0])})
    }else {
      self.setState({groupImage: ""})
    }

  }

  setImageForTrophy(event, index){
    const name = event.target.name;
    const file = event.target.files[0];
    let proceed = this.validateImageTypeForTrophy(index, name, file)

    if (!this.state.validatedImgs[index]) {
      this.state.validatedImgs[index] = true;
    }


    if (proceed) {
      this.state.validatedImgs[index] = true;
      this.state.trophies[index][name] = file;

      this.state.trophies[index][name+'Display'] = (file) ? URL.createObjectURL(file) : "";
      this.selectedImages.push({index, name, file, folder: name, type: 'list'});
      this.setState({trophies:this.state.trophies, validatedImgs : this.state.validatedImgs});
    }else{
      this.state.trophies[index][name+'Display'] = "";
      this.state.validatedImgs[index] = false;
      this.setState({validatedImgs : this.state.validatedImgs, trophies : this.state.trophies})
    }
  }

  validateImageTypeForTrophy(index, name, file){
    let self = this;
    let trophyImageError = self.state.trophyImageError;
    //let {contentType} = this.state;
    if(!file){
      trophyImageError[index][name] = ''
      self.setState({trophyImageError: trophyImageError});
      return true;
    }else if(['image/jpeg', 'image/png'].indexOf(file.type) === -1){
      trophyImageError[index][name] = 'Please select valid image(.jpg, .png) file.'
      self.setState({trophyImageError: trophyImageError}, ()=>{
        // console.log('this', this.state);
      });
      return false;
    } else {
      trophyImageError[index][name] = ''
      self.setState({trophyImageError: trophyImageError});
      return true;
    }
  }
  validateImageType(name, file){
    let self = this;
    let currentCustomValidatorMsg = self.state.customValidatorMsg;
    //let {existingContentType, contentType} = this.state;
    if(!file){
      currentCustomValidatorMsg[name] = '';
      self.setState({customValidatorMsg: currentCustomValidatorMsg});
      return true;
    }else if(['image/jpeg', 'image/png'].indexOf(file.type) === -1){
      currentCustomValidatorMsg[name] = 'Please select valid image(.jpg, .png) file.';
      self.setState({customValidatorMsg: currentCustomValidatorMsg});
      return false;
    } else {
      currentCustomValidatorMsg[name] = '';
      self.setState({customValidatorMsg: currentCustomValidatorMsg});
      return true;
    }

  }

  onSelect(item){
    this.setState({selectedPillar: item, typedContent: item.title, pillarId : item['_id']})
  }

  addNewTrophy(event) {
    this.state.trophies.push({
      defaultPoints: (this.state.trophies.length) ? _.last(this.state.trophies)['defaultPoints'] + 100 : 1, 
      systemLanguages : this.state.systemLanguages,
      image: "",
      pillarImage: "",
      trophyPlaceholderImage: "",
      imageDisplay: "",
      pillarImageDisplay: "",
      trophyPlaceholderImageDisplay: ""
    })
    this.state.trophyImageError.push({image: null, pillarImage: null, trophyPlaceholderImage: null});
    this.setState({
      trophiesCount : this.state.trophiesCount + 1,
      trophies : this.state.trophies,
      trophyImageError: this.state.trophyImageError
    });


    event.preventDefault();
  }



  trophyCollectionChange(event, index) {
    let target = event.currentTarget;
    let name = target.name;
    let value = target.value;
    if (name === "defaultPoints") {
      value = parseInt(value);
    }

    this.state.trophies[index][name] = value;
    this.setState({trophies : this.state.trophies});
  }

  removeTrophyRow(index)  {
    this.state.trophies.splice(index, 1);
    this.setState({trophies : this.state.trophies})
  }

  


  PillarOptions(pillarData){
    let pillarOptions = []
    _.each(pillarData, function(pillarObj, index){
      pillarOptions.push(
        <option key={pillarObj._id} value={pillarObj._id}>{pillarObj.title}</option>
      )
    })

    return pillarOptions;
  }

  addRemovePillars(event) {
    let self = this
    let options = event.currentTarget.options;
    self.state.pillarData = [];
    for (let i = 0, l = options.length; i < l; i++) {
      if (options[i].selected) {
        let pillarObj = {}
        if(_.findWhere(self.props.pillarData, {_id : options[i].value})) {
          pillarObj = _.findWhere(self.props.pillarData, {_id : options[i].value});
          self.state.pillarData.push({
            pillarId : pillarObj['_id'],
            slug : pillarObj['slug'],
            pillarTitle : pillarObj['title'],
          })
        }
      }
    }

    self.setState({pillarData : self.state.pillarData});
    event.preventDefault();
  }

  setTitleDesc(event, trophyIndex, langIndex, languageSlug) {
    let self = this;
    let name = event.target.name; 
    let value = event.target.value;

    self.state.trophies[trophyIndex]['systemLanguages'][langIndex]['trophyLang'][name] = value;
    self.state.trophies[trophyIndex]['systemLanguages'][langIndex]['trophyLang']['languageSlug'] = languageSlug;
    this.setState({
      trophies : self.state.trophies
    })
  }


  TitleDescriptionLayout(trophy, trophyIndex) {
     let self = this;
    let trophyHtml = [];
    let { trophies } = this.state;
    _.each(trophies[trophyIndex].systemLanguages, function(langObj, langIndex){
      trophyHtml.push(
            <Col key={langObj.title + langObj.slug + langIndex + trophyIndex} xs="6">
              <Row>
                <Col xs="12">
                    <FormGroup>
                      <Label htmlFor={langObj.title + langObj.slug + langIndex + trophyIndex}>{langObj.title} Title</Label>
                      <Input type="text" id={langObj.title + langObj.slug + langIndex + trophyIndex} placeholder="Title" name="title" value={self.state.trophies[trophyIndex]['systemLanguages'][langIndex]['trophyLang']['title']} onChange={(event)=>{self.setTitleDesc(event, trophyIndex, langIndex, langObj['slug'])}} />                                              
                      


                      <div className="validation-error">{ self.validator.message(langObj.title + ' title', self.state.trophies[trophyIndex]['systemLanguages'][langIndex]['trophyLang']['title'], 'required') }</div>   
                    </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col xs="12">
                    <FormGroup>
                        <Label htmlFor={langObj.title + langObj.slug}>{langObj.title} Short Description</Label>                        
                        <Input type="textarea" id={langObj.trophyDescription + langObj.slug} placeholder="Description" name="trophyDescription" value={self.state.trophies[trophyIndex]['systemLanguages'][langIndex]['trophyLang']['trophyDescription']} onChange={(event)=>{self.setTitleDesc(event, trophyIndex, langIndex, langObj['slug'])}} />
                        {<div className="validation-error">{ self.validator.message(langObj.trophyDescription + ' trophyDescription', self.state.trophies[trophyIndex]['systemLanguages'][langIndex]['trophyLang']['trophyDescription'], 'required') }</div> }
                    </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col xs="12">
                    <FormGroup>
                        <Label htmlFor={langObj.title + langObj.slug}>{langObj.title} Description</Label>                        
                        <Input type="textarea" id={langObj.title + langObj.slug} placeholder="Description" name="info" value={self.state.trophies[trophyIndex]['systemLanguages'][langIndex]['trophyLang']['info']} onChange={(event)=>{self.setTitleDesc(event, trophyIndex, langIndex, langObj['slug'])}} />
                        {<div className="validation-error">{ self.validator.message(langObj.title + ' info', self.state.trophies[trophyIndex]['systemLanguages'][langIndex]['trophyLang']['info'], 'required') }</div> }
                    </FormGroup>
                </Col>
              </Row>
            </Col>
      );
    })

    return trophyHtml;
  }

  TrophyAttrubutesLayout() {
    let self = this;
    let trophyHtml = [];
    let {trophies, trophyPlaceholderImage } = this.state;
    _.each(trophies, function(trophy, index){
      let imageName = self.state.trophies[index]['image'] && typeof self.state.trophies[index]['image'] === 'object' ? self.state.trophies[index]['image'].name : self.state.trophies[index]['image'];
      let pillarImage = self.state.trophies[index]['pillarImage'] && typeof self.state.trophies[index]['pillarImage'] === 'object' ? self.state.trophies[index]['pillarImage'].name : self.state.trophies[index]['pillarImage'];
      let trophyPlaceholderImage = self.state.trophies[index]['trophyPlaceholderImage'] && typeof self.state.trophies[index]['trophyPlaceholderImage'] === 'object' ? self.state.trophies[index]['trophyPlaceholderImage'].name : self.state.trophies[index]['trophyPlaceholderImage'];

      trophyHtml.push(
        <div key={index} style={{backgroundColor: '#dee0e2', padding: 20, borderRadius: 5, marginBottom: 20, position:'relative'}}>
          <Button  size="sm" color="danger" className="remove-trophy" onClick={(event) => { self.removeTrophyRow(index) }} disabled={self.state.trophies.length < 2}>Remove Trophy</Button>
          <Row>{self.TitleDescriptionLayout(trophy, index)}</Row>
         

          <Row>
            <Col xs="4">
              <FormGroup>
                <Label htmlFor="title">Points</Label>
                <Input type="number" id="defaultPoints" placeholder="Points" name="defaultPoints" value={self.state.trophies[index]['defaultPoints']} onChange={(event)=>{self.trophyCollectionChange(event, index);}}   min="1" />                                              
                <div className="validation-error">{ self.validator.message('Points', self.state.trophies[index]['defaultPoints'], 'required') }</div>
                
              </FormGroup>
            </Col>
          </Row>
          <hr/>          
          <Row>
           
            <Col xs="4">
            <Label htmlFor={'image_'+index}>Banner Image for Trophy winning screen</Label>
            <div className="trophy-img-wrap">
                <FormGroup>
                  {
                    (trophy.imageDisplay) ? <img src={trophy.imageDisplay} alt=""  /> :  <div className="placeholder-trophy">No Image Selected</div>
                  }
                  <Input type="file" id={'image_'+index} name="image" onChange={(event)=>{self.setImageForTrophy(event, index);}}   min="1" style={{display: 'none'}} />
                  <label htmlFor={'image_'+index} className="btn btn-primary" style={{display: 'block'}}>{imageName ? imageName : 'Choose Banner Image'}</label>                                        

                  <div className="validation-error align-validation-text">{ self.validator.message('Banner Image', self.state.trophies[index]['image'], 'required') }</div>

                  {/*((self.state.trophyImageError && self.state.trophyImageError[index] && !self.state.trophyImageError[index]['image']) && !self.state.trophies[index]['image']) ? <div className="validation-error align-validation-text">{ self.validator.message('Banner Image', self.state.trophies[index]['image'], 'required') }</div> : null*/}

                  {(self.state.trophyImageError && self.state.trophyImageError[index] && self.state.trophyImageError[index]['image']) ?  <div className="validation-error align-validation-text">{ self.state.trophyImageError[index]['image']}</div> : null}


                </FormGroup>
             </div>
            </Col>
            <Col xs="4">
            <Label htmlFor={'pillarImage_'+index}>Trophy Image for Trophy Room</Label>
              <div className="trophy-img-wrap">
                  <FormGroup>
                    {
                      (trophy.pillarImageDisplay) ? <img src={trophy.pillarImageDisplay} alt=""  /> :  <div className="placeholder-trophy">No Image Selected</div>
                    }
                    <Input type="file" id={'pillarImage_'+index} name="pillarImage" onChange={(event)=>{self.setImageForTrophy(event, index);}} min="1" style={{display: 'none'}}/>  
                    <label htmlFor={'pillarImage_'+index} className="btn btn-primary" style={{display: 'block'}}>{pillarImage ? pillarImage : 'Choose Trophy Room Image'}</label>                                            
                    <div className="validation-error align-validation-text">{ self.validator.message('Pillar Image', self.state.trophies[index]['pillarImage'], 'required') }</div>

                    {/*((self.state.trophyImageError && self.state.trophyImageError[index] && !self.state.trophyImageError[index]['pillarImage']) && !self.state.trophies[index]['pillarImage']) ? <div className="validation-error align-validation-text">{ self.validator.message('Pillar Image', self.state.trophies[index]['pillarImage'], 'required') }</div> : null*/}

                    {(self.state.trophyImageError && self.state.trophyImageError[index] && self.state.trophyImageError[index]['pillarImage']) ?  <div className="validation-error align-validation-text">{ self.state.trophyImageError[index]['pillarImage']}</div> : null}
                  </FormGroup>
              </div>
            </Col>
            <Col xs="4">
            <Label htmlFor={'trophyPlaceholderImage_'+index}>Trophy Placeholder Image for Trophy Room</Label>
              <div className="trophy-img-wrap">
                  <FormGroup>
                    {
                      (trophy.trophyPlaceholderImageDisplay) ? <img src={trophy.trophyPlaceholderImageDisplay} alt=""  /> :  <div className="placeholder-trophy">No Image Selected</div>
                    }
                    <Input type="file" id={'trophyPlaceholderImage_'+index} name="trophyPlaceholderImage" onChange={(event)=>{self.setImageForTrophy(event, index);}} min="1" style={{display: 'none'}}/>  
                    <label htmlFor={'trophyPlaceholderImage_'+index} className="btn btn-primary" style={{display: 'block'}}>{trophyPlaceholderImage ? trophyPlaceholderImage : 'Choose Trophy Room Image'}</label>                                            
                    <div className="validation-error align-validation-text">{ self.validator.message('Pillar Placeholder Image', self.state.trophies[index]['trophyPlaceholderImage'], 'required') }</div>

                    {(self.state.trophyImageError && self.state.trophyImageError[index] && self.state.trophyImageError[index]['trophyPlaceholderImage']) ?  <div className="validation-error align-validation-text">{ self.state.trophyImageError[index]['trophyPlaceholderImage']}</div> : null}
                  </FormGroup>
              </div>
            </Col>
          </Row>
          
        </div>
      );
    })

    return (
      <div>
        {trophyHtml}
        <Row>
            <Col xs="10">&nbsp;</Col>
            <Col xs="2">
              <Button  size="sm" color="primary" onClick={(event) => { this.addNewTrophy(event) }}>Add More Trophy</Button>
            </Col>            
        </Row>
      </div>
    )
  }

  changeTrophyTypes(event, index, languageSlug) {

  }


  changeTrophyTypes(event, index, languageSlug) {
    this.state.trophyTypes[index][event.target.name] = event.target.value;
    this.state.trophyTypes[index]['languageSlug'] = languageSlug;
    this.setState({trophyTypes : this.state.trophyTypes})
  }


  TrophyTypeSection() {
     let self = this;
    return this.state.trophyTypes.map(function(obj, index){
      return (
        <Col xs="6" key={obj.title}>
            <FormGroup>
              <Label htmlFor="title">{obj.title} Type</Label>
              <Input type="text" id={obj.title + 'Type'} placeholder="Type" name="type" value={self.state.trophyTypes[index]['type']} onChange={(event)=>{self.changeTrophyTypes(event, index, obj.languageSlug)}} />                                              
              {<div className="validation-error">{ self.validator.message(obj.title + ' type', self.state.trophyTypes[index]['type'], 'required') }</div> }  
            </FormGroup>
        </Col>
      );
    })
  }


  render() {
    let self = this;
    let { groupImage } = this.state;
    let { openAddTrophyModal, closeModal, addTrophyPopupError, trophyFreePillars } = this.props;
     
     let typeImage = this.state.typeImage && typeof this.state.typeImage === 'object' ? this.state.typeImage.name : this.state.typeImage;
     

    return (
      <Modal isOpen={openAddTrophyModal} size="lg">
          <ModalHeader toggle={()=>{closeModal(false)}}>Add Trophy</ModalHeader>
          <Form>  
            <ModalBody>
              
              {self.TrophyAttrubutesLayout()}  
              <div style={{padding: 20, borderTop: '2px solid #dee0e2', marginTop: 20, marginBottom : "auto"}}>
                <Row>
                  <Col xs="4">
                      <FormGroup>
                        <Label htmlFor="pillars">Pillars</Label>
                          <Input type="select" name="pillars[]" id="pillars" onChange={($event)=>{this.addRemovePillars($event)}} multiple className="multi-select">
                            {this.PillarOptions(trophyFreePillars)}
                          </Input>
                          {/*<div className="validation-error">{ this.validator.message('pillar', this.state.pillarData, 'required') }</div>*/}   
                      </FormGroup>
                  </Col>
                  <Col xs="8">
                    <Row>
                     {this.TrophyTypeSection()}
                    </Row>
                    <Row>                                 
                      <Col xs="4">
                        <FormGroup>
                            <Label htmlFor="color">Background Color</Label>
                            <Input type="color" name="color" id="color" value={this.state.color} onChange={(event)=>{this.handleChange(event)}}></Input>
                        </FormGroup>
                      </Col>
                    
                      <Col xs="4">
                        <div className="trophy-img-wrap">
                              <FormGroup>                          
                                  {
                                    (groupImage) ? <img src={groupImage} alt=""  /> :  <div className="placeholder-trophy">No Image Selected</div>
                                  }
                                  <Input type="file" name="typeImage" id="typeImage" onChange={(event) => this.setImg(event)}  style={{display: 'none'}}  />

                                  <label htmlFor="typeImage" className="btn btn-primary" style={{display: 'block'}}>{typeImage ? typeImage : 'Choose A Group Image'}</label>
                                  
                                  {(!self.state.customValidatorMsg['typeImage'] && self.state.typeImage) ? <div className="validation-error">{ self.validator.message('Group Image', self.state.typeImage, 'required') }</div> : null}

                                  {<div className="validation-error">{this.state.customValidatorMsg['typeImage'] && <div className="srv-validation-message">{this.state.customValidatorMsg['typeImage']}</div>}</div>}
                              </FormGroup>
                          </div>
                      </Col>
                      <Col xs="4">
                          <FormGroup>
                              <Label htmlFor="status">Status</Label>
                              <div>
                                <AppSwitch className={ 'mx-1'} variant={ 'pill'} color={ 'primary'}  id="status"  name="status"
                                  onChange={($event)=>{this.handleChange($event)}}
                                  checked={(this.state.status)}
                                  value={this.state.status ? 'true' : 'false'} />
                              </div>
                          </FormGroup>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </div>
              {(addTrophyPopupError) ? <Alert color="danger">{addTrophyPopupError}</Alert> : null} 

            </ModalBody>

            <ModalFooter className="justify-content-right">
              <Button type="button" onClick={(event)=>{this.handleSubmit(event);}} size="sm" color="primary">Save</Button>
              <Button type="reset" size="sm" color="danger"  onClick={() => { closeModal() }}>Cancel</Button>
            </ModalFooter>
          </Form>
        </Modal>
      
    );
  }
}

export default AddTrophy;
