export const _local = {
    "APP_ENV": "local",
    "APP_PUBLIC_URL": "http://localhost:3001",
    "API_URL": "http://ninjaapi.gate6.com",
    "API_KEY": "B86897CA1FA567B7F7LL2975C3AB5A09CE1C9F9CB29CE4390CB9FE9CED847AC8",
    "DATA_ENCRYPT_KEY": "B86897CA1FA567B7F7LL2975C3AB5A09CE1C9F9CB29CE4390CB9FE9CED847AC8",
    "MEDIA_FILE_TYPES": ['audio/mp3', 'audio/mpeg', 'audio/wav', 'audio/x-wav', 'audio/flac', 'audio/aac', 'audio/x-ms-wma', 'audio/x-aiff', 'audio/x-m4a', 'audio/mp4'],
    "IMAGE_FILE_TYPES": ['image/gif', 'image/jpeg', 'image/png']
}

export const _dev = {
    "APP_ENV": "local",
    "APP_PUBLIC_URL": "http://testadmin.ninjafocus.com",
    "API_URL": "http://devapi.ninjafocus.com",
    "API_KEY": "B86897CA1FA567B7F7LL2975C3AB5A09CE1C9F9CB29CE4390CB9FE9CED847AC8",
    "DATA_ENCRYPT_KEY": "B86897CA1FA567B7F7LL2975C3AB5A09CE1C9F9CB29CE4390CB9FE9CED847AC8",
    "MEDIA_FILE_TYPES": ['audio/mp3', 'audio/mpeg', 'audio/wav', 'audio/x-wav', 'audio/flac', 'audio/aac', 'audio/x-ms-wma', 'audio/x-aiff', 'audio/x-m4a', 'audio/mp4'],
    "IMAGE_FILE_TYPES": ['image/gif', 'image/jpeg', 'image/png']
}

export const _test = {
  "APP_ENV": "local",
 "APP_PUBLIC_URL": "http://testadmin.ninjafocus.com/",
  "API_URL": "http://testapi.ninjafocus.com",
  "API_KEY": "B86897CA1FA567B7F1DD6975C3AB5A09CE1C9F9CB29CE4390CB9FE9CED847AC8",
  "DATA_ENCRYPT_KEY": "B86897CA1FA567B7F7LL2975C3AB5A09CE1C9F9CB29CE4390CB9FE9CED847AC8",
  "MEDIA_FILE_TYPES": ['audio/mp3', 'audio/mpeg', 'audio/wav', 'audio/x-wav', 'audio/flac', 'audio/aac', 'audio/x-ms-wma', 'audio/x-aiff', 'audio/x-m4a', 'audio/mp4'],
  "IMAGE_FILE_TYPES": ['image/gif', 'image/jpeg', 'image/png']
}
export const _stage = {
  "APP_ENV": "local",
 "APP_PUBLIC_URL": "http://ninjaapi.gate6.com:5000",
    "API_URL": "http://ninjaapi.gate6.com",
  "API_KEY": "2AFC72C540AF077A908AE181B5F10D343C515E93B697E4B06E4C166CA4CF5C0B",
  "DATA_ENCRYPT_KEY": "B86897CA1FA567B7F7LL2975C3AB5A09CE1C9F9CB29CE4390CB9FE9CED847AC8",
  "MEDIA_FILE_TYPES": ['audio/mp3', 'audio/mpeg', 'audio/wav', 'audio/x-wav', 'audio/flac', 'audio/aac', 'audio/x-ms-wma', 'audio/x-aiff', 'audio/x-m4a', 'audio/mp4'],
  "IMAGE_FILE_TYPES": ['image/gif', 'image/jpeg', 'image/png']
}

export const _production = {
  "APP_ENV": "local",
  "APP_PUBLIC_URL": "https://api.ninjafocus.com",
    "API_URL": "https://api.ninjafocus.com",
  "API_KEY": "2AFC72C540AF077A908AE181B5F10D343C515E93B697E4B06E4C166CA4CF5C0B",
  "DATA_ENCRYPT_KEY": "B86897CA1FA567B7F7LL2975C3AB5A09CE1C9F9CB29CE4390CB9FE9CED847AC8",
  "MEDIA_FILE_TYPES": ['audio/mp3', 'audio/mpeg', 'audio/wav', 'audio/x-wav', 'audio/flac', 'audio/aac', 'audio/x-ms-wma', 'audio/x-aiff', 'audio/x-m4a', 'audio/mp4'],
  "IMAGE_FILE_TYPES": ['image/gif', 'image/jpeg', 'image/png']
}

const config = function getConfig(mode) {
    switch (mode) {
        case 'local':
            return _local;
        case 'dev':
          return _dev;
        case 'test':
          return _test;
        case 'stage':
            return _stage;
        case 'production':
            return _production;
        default:
            return _dev;
    }
}

export default config(process.env.REACT_APP_MODE);
