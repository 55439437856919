  import React, { Component } from 'react';
import { Loader } from '../../utils/common';
import { Row, Col, Alert,  Modal, Button, ModalHeader, ModalBody, ModalFooter, Table, Nav, NavItem, NavLink } from "reactstrap";
import { AppSwitch } from '@coreui/react' 
import PrintableForm from './PrintableForm';  
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import ReactPaginate from 'react-paginate';
import Session from '../../utils/session';


class Printable extends Component {

  constructor(props) {
    super(props);
    this.timeout = null;
    this.state = {
      printableList : [],
      totalRows: 0,
      rowsPerPage: 10,
      page:0,
      limit : 10,
      offset : 0,      
      selectedObj: null,
      deleteRowData: {},
      visible: false,
      signedQueryString: null
    }
    this.listRecord = 0;
    this.printableColumns = [
      { "columnName": "mediaUrl", "displayName": "Image", sort : true},
    ];
    this.session = Session.getSessionData('config');
    this.cloudFrontUrl = this.session && this.session.UTILS && this.session.UTILS.BUCKET_URL ? this.session.UTILS.BUCKET_URL : process.env.REACT_APP_CLOUDFRONT_URL;

  }

  componentDidMount() {
    this.getAllFeePrintable();
  }

  

  componentWillReceiveProps(nextProps) {
    let self = this;
    Loader(nextProps.requestProcess);  
    if ((nextProps.data && nextProps.data.statusCode === 200)) {
      let responseData = nextProps && nextProps.data && nextProps.data.result ? nextProps.data.result : {list: [], total: 0};
      this.listRecord = responseData.list.length;
      self.setState({printableList : responseData.list, totalRows: responseData.total, signedQueryString: nextProps.data.signedQueryString});  
    }
    
    if (nextProps.addUpdateData && nextProps.addUpdateData.data && nextProps.addUpdateData.data.statusCode === 200) {
      self.closeModal();
      self.getAllFeePrintable();
    }

  }



  getAllFeePrintable() {
    let {limit, offset} = this.state;
    let params = {
      limit : limit,
      offset : offset
    }
    this.props._getAllFeePrintable(params);
  }


  closeModal(){
    this.setState({deleteRowData: {}, visible: false });
  }


  changeStatus(rowData){
    rowData['status'] = rowData['status'] ? false : true;
    rowData['media'] = rowData['mediaUrl']
    this.props._updatePrintable(rowData).then( resaponse => {
      this.getAllFeePrintable();
    }, error => {
      this.setState({errorMessage: error.message}, ()=>{
          setTimeout(()=>{
            this.setState({errorMessage: null})
          }, 3000)
        })
    });
  }
  deleteContent(rowObj){
    this.setState({deleteRowData: rowObj, visible: true})
  }
 
  confirmDelete(){
    let {deleteRowData, limit, offset, page} = this.state;

    this.setState({errorMessage: null})
    if(deleteRowData && Object.keys(deleteRowData).length > 0){
      this.props._deleteAdminUser({_id: deleteRowData._id}).then( res => {
        if(this.listRecord === 1){
            this.listRecord = 0;
            this.setState({offset: (offset - limit), page: page -1}, ()=>{
              this.getAllFeePrintable();  
            })            
        }else{
          this.getAllFeePrintable();  
        }
      }, error => {
        this.setState({errorMessage: error.message}, ()=>{
          setTimeout(()=>{
            this.setState({errorMessage: null})
          }, 3000)
        })
      });
      setTimeout(()=>{this.closeModal();}, 1000);
    }
  }

  render() {
    let self = this;
    let { printableList, signedQueryString, page, limit,  addUpdateData, totalRows, visible, errorMessage } = this.state;
    
    let linksCount = (totalRows > 0 && limit > 0) ? Math.ceil(totalRows / limit) : 0;
    console.log('self.cloudFrontUrl', self.cloudFrontUrl)
    return (
      <div className="content">        
        <Row className="pb-4">
          <Col lg={6} sm={12}>
            <Row className="pb-4">
              <Col lg={12} sm={12}>
                <h3>Printable</h3>                 
              </Col>             
            </Row>
            <Row className="mb-4">            
                <Col sm={12} >{errorMessage ? <Alert color="danger">{errorMessage}</Alert> : null}</Col>
              </Row>
              <div className="dataTable-wrapper pillarList_grid">
                <Table responsive hover>
                  <thead>
                    <tr>
                      <th>Image</th>
                      <th>Action</th>                      
                    </tr>
                  </thead>
                  <tbody>
                    {printableList && printableList.length > 0 ?
                      printableList.map((element, index)=>{
                        return <tr key={index}>
                          <td><img style={{width: 100, height: 100}} src={self.cloudFrontUrl+element.mediaUrl+signedQueryString} alt={element.mediaUrl} /></td>
                          <td>
                            <div>            
                              <Nav>
                                <NavItem>
                                  <NavLink href="/" onClick={(e) => { this.deleteContent(element); e.preventDefault();}}><FontAwesomeIcon icon={faTrashAlt}  size='lg' /></NavLink>
                                </NavItem>
                                
                                <NavItem>
                                  <AppSwitch className={'mx-1'} variant={'pill'} color={'primary'} label checked={element.status} onChange={() => { this.changeStatus(element); }} />
                                </NavItem>                
                              </Nav>
                            </div>
                          </td>
                        </tr>
                      })
                      :
                      <tr>
                        <td colSpan={10} style={{textAlign: 'center'}}>No Records Found</td>
                      </tr>
                    }                  
                  </tbody>
                  <tfoot>
                    <tr>
                      <td colSpan={10} style={{textAlign: 'center'}}>
                        <div>
                          <span style={{float: 'left'}}>Total Printables:  {totalRows}</span>                          
                          <ReactPaginate
                            previousLabel={''}
                            nextLabel={''}
                            breakLabel={'...'}
                            breakClassName={'break-me'}
                            pageCount={linksCount}
                            marginPagesDisplayed={2}
                            pageRangeDisplayed={5}          
                            forcePage={parseInt(page)}
                            onPageChange={(e) => {
                              let offset = e.selected;
                              this.setState({offset : offset * limit, page: offset}, ()=>{
                                  this.getAllFeePrintable()
                              })
                            }}
                            containerClassName={'pagination float-right'}
                            pageClassName={'page-item'}
                            pageLinkClassName={'page-link'}
                            activeClassName={'active'}
                            breakClassName={'page-link'}
                          />
                        </div>
                      </td>
                    </tr>
                  </tfoot>
                </Table>
              
              </div> 
          </Col>
          <Col lg={6} sm={12}>
            <PrintableForm getAllFeePrintable={()=>{this.getAllFeePrintable()}} {...this.props} />
          </Col>
        </Row>
        <Modal isOpen={visible}>
            <ModalHeader toggle={()=>{this.closeModal(false)}}>Delete Printable</ModalHeader>
            <ModalBody>
              Are you sure, want to delete?
            </ModalBody>
            <ModalFooter className="justify-content-left">
              <Button type="button" size="sm" color="primary" onClick={()=>{this.confirmDelete()}}>Delete</Button>{' '}
              <Button type="reset" size="sm" color="danger"  onClick={() => { this.closeModal() }}>Cancel</Button>
            </ModalFooter>
        </Modal>
      </div>

      
    );
  }
}

export default Printable;
