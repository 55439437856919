import React, { Component } from 'react';
import { Loader, createRandomStr } from '../../utils/common';
import { Row, Col, Button, FormGroup, Label, Input, Form , Alert, Card, CardHeader, CardBody, CardFooter } from "reactstrap";
import { AppSwitch } from '@coreui/react'
import S3Client from '../../services/aws-s3/S3Client';
import httpClient from '../../services/http';

class PrintableForm extends Component {

  constructor(props) {
    super(props);  
    
    this.state = {
      media : '',
      docFile: '',
      mediaUrl: null,
      status : true,
      errorMessage: null,
      successMessage: null
    }
    const s3Config = {
        bucketName: process.env.REACT_APP_S3_BUCKET,
        dirName: 'printable', /* optional */
        region: process.env.REACT_APP_AWS_DEFAULT_REGION,
        accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
        s3Url: process.env.REACT_APP_AWS_BUCKET_URL, /* optional */
    }
    this.S3Client = new S3Client(s3Config);
  }

  componentDidMount() {
  }

  componentWillReceiveProps(nextProps) {
    Loader(nextProps.requestProcess);
  }


  handleChange(event) {
    let target = event.target;
    let name = target.name;
    let value = target.value;  
    this.setState({[name]: value === 'Active' ? false: true});       
  }

  formReset(){
    this.setState({
      media : '',
      docFile: '',
      mediaUrl: null,
      status : true,
      errorMessage: null,
      successMessage: null
    })
  }


  handleSubmit(event) {
    let self = this;
    //let responseObj = {};
    //let err = null;

    let {media, docFile, status} = this.state;
    
    event.preventDefault();

    Loader(true);
    if(media instanceof File && docFile instanceof File){
      self.S3Client.getPolicy().then((policyRes)=>{
        httpClient.call('PILLAR', '/get_signature', {policy: policyRes}, {method: 'GET', headers: {}}).then((signatureRes)=>{
          if(signatureRes && signatureRes.statusCode === 200 && signatureRes.result && signatureRes.result.signature){
                createRandomStr((randString)=>{ 

                  let signature = signatureRes.result.signature;
                  let ext = media.name.split('.').pop();

                  self.S3Client.uploadFile(media, randString+'.'+ext, signature).then(data => {
                    let params = {
                      media: data.key,
                      status
                    }
                    self.uplaodPrintableDoc(randString, signature).then( docFilePath => {
                      params['docFile'] = docFilePath;
                      this.props._addPrintable(params).then( response => {
                        if(response.statusCode === 200){
                          this.setState({media : '', docFile: '', mediaUrl: null, status : true, errorMessage: null, successMessage: response.statusDescription}, ()=>{
                              this.props.getAllFeePrintable();  
                              setTimeout(()=>{
                                this.setState({successMessage: null})
                              }, 3000)
                          })
                        }else{
                          this.setState({errorMessage: response.statusDescription})  
                        }
                      }, error => {
                        this.setState({errorMessage: error.message})
                      });

                    }, error => {
                        this.setState({errorMessage: error.message})
                    })

                    Loader(true);
                  }).catch(err => {
                    console.log('err', err)
                    this.setState({errorMessage: err.message})
                    Loader(false);
                  })


                });
              
          }else{
            console.log('ERROR:- SIGNATURE RESPONSE ', signatureRes);
            this.setState({errorMessage: signatureRes.statusDescription})
            Loader(false);
          }   

        }, (error) => {
          console.log('ERROR:- SIGNATURE REQUEST ', error);
          this.setState({errorMessage: error.message})
          Loader(false);
        })
      }, (error) => {
        console.log('ERROR:- SIGNATURE REQUEST ', error);
        this.setState({errorMessage: error.message})
        Loader(false);
      });
    }else{
      let message = (media instanceof File) ? 'Printable Download media is required' : 'Printable Preview media is required';
      this.setState({errorMessage: message}, ()=>{
        setTimeout(()=>{
          this.setState({errorMessage: null})
        }, 3000)
      })
      Loader(false);
    }
    event.preventDefault();
  }


  uplaodPrintableDoc(randString, signature){
    return new Promise((resolve, reject) => {
      let {docFile} = this.state;
      if(docFile instanceof File){
        let ext = docFile.name.split('.').pop();
        this.S3Client.uploadFile(docFile, randString+'_doc.'+ext, signature).then(data => {
          resolve(data.key);
        }, error => {
          reject(error);
        })
      }else{
        resolve(null);
      }
    })
  }




  onSelect(item){
    this.setState({contentId: item._id, contentTitle: item.fullName, typedContent: item.fullName})    
  }


  verifyMedia(event){
    let file = event.target.files && event.target.files.length > 0 ? event.target.files[0] : null;
    if(file instanceof File){
      if([  'image/jpeg', 'image/png'].indexOf(file.type) === -1){
        this.setState({media: null, mediaUrl: null, errorMessage: "Please select valid image(.jpg, .png) file.", media: ''}, ()=>{
          setTimeout(()=>{
            this.setState({errorMessage: null})
          }, 3000)
        });
      } else {
        let reader = new FileReader();
        reader.onload = (e) => {
          this.setState({media: file, mediaUrl: e.target.result});
        };
        reader.readAsDataURL(file);
      }
    }else{
      this.setState({media: null, mediaUrl: null,errorMessage: "Preview Media is not avalable"}, ()=>{
        setTimeout(()=>{
          this.setState({errorMessage: null})
        }, 3000)
      })
    }

  }

  verifyDocMedia(event){
    let docFile = event.target.files && event.target.files.length > 0 ? event.target.files[0] : null;
    if(docFile instanceof File){
      if(['application/pdf', 'image/jpeg', 'image/png'].indexOf(docFile.type) === -1){
        this.setState({ docFile: null, errorMessage: "Please select valid Image/PDF(.pdf, .jpg, .png) file.", docFile: ''}, ()=>{
          setTimeout(()=>{
            this.setState({errorMessage: null})
          }, 3000)
        });
      } else {
        this.setState({docFile: docFile});
      }
    }else{
      this.setState({docFile: null, errorMessage: "Downloadable Media is not avalable"}, ()=>{
        setTimeout(()=>{
          this.setState({errorMessage: null})
        }, 3000)
      })
      
    }
  }

  render() {
    //let { openAddAdminUserModal, closeModal } = this.props;
    let {errorMessage, media, docFile, mediaUrl, successMessage} = this.state;
    return (
        <Row>
          <Col lg={12} sm={12}>
          
          <Form onSubmit={(event) => {this.handleSubmit(event)}} autoComplete="off">  
            <Card>
              <CardHeader>New Printable</CardHeader>
              <CardBody>
                  <Row>   
                  <Col xs="12">
                    <FormGroup>
                      <Row>
                        <Col md="4" xs="12"><Label for="fullImage">Printable Preview<br/><small>(JPG,PNG)</small></Label></Col>
                        <Col md="8" xs="12">
                          <div style={{marginTop:0}} className="image-upload-wrap pointer" onClick={()=>{document.getElementById('media').click()}}>
                            {mediaUrl && <img widtg="100" height="100" className="file-upload-image" src={mediaUrl} alt="your image" />}
                            <Input className="file-upload-input"  style={{display: 'none'}} type="file" name={'media'} accept="image/*" id="media" onChange={(event) =>  this.verifyMedia(event)} />
                            <div className="drag-text">
                              <h3>{!mediaUrl && "Select to add preview Image"}</h3>
                            </div>
                          </div>

                        </Col>                      
                      </Row>
                      
                    </FormGroup>
                  </Col>
                   <Col xs="12">
                    <FormGroup>
                      <Row>
                        <Col md="4" xs="12"><Label for="fullImage">Printable Downloadable<br/><small>(JPG,PNG,PDF)</small></Label></Col>
                        <Col md="8" xs="12">
                          <div style={{marginTop:0}} className="image-upload-wrap pointer" onClick={()=>{document.getElementById('docFile').click()}}>
                            <Input className="file-upload-input"  style={{display: 'none'}} type="file" name={'docFile'} accept="pdf/*" id="docFile" onChange={(event) =>  this.verifyDocMedia(event)} />
                            <div className="drag-text">
                              <h3>{docFile ? docFile.name : "Select to add downloadable"}</h3>
                            </div>
                          </div>

                        </Col>                      
                      </Row>
                      
                    </FormGroup>
                  </Col>
                
                  <Col xs="12">
                    <FormGroup>
                      <Row>
                        <Col md="4" xs="12"><Label for="fullImage">Printable Status</Label></Col>
                        <Col md="8" xs="12">
                          <AppSwitch className={ 'mx-1'} variant={ 'pill'} color={ 'primary'}  id="status"  name="status"
                            onChange={(event)=>{this.handleChange(event)}}
                            checked={this.state.status}
                            value={this.state.status ? 'Active': 'Inactive'} />
                        </Col>
                      </Row>
                    </FormGroup>
                  </Col>
                </Row>
              </CardBody>
              <CardFooter>

              {errorMessage ? <Alert color="danger">{errorMessage}</Alert> : null}
              {successMessage ? <Alert color="success">{successMessage}</Alert> : null}              
              <div className="float-right">
                
                  <Button type="button" size="sm" color="danger" onClick={()=>{this.formReset()}}>Reset</Button>{' '}
                  <Button type="submit" size="sm" color="primary">Save</Button>{' '}
                </div>
              
              </CardFooter>
            </Card>
          </Form>
          </Col>
        </Row>
    );
  }
}

export default PrintableForm;
