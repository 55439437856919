import React, { Component } from 'react';
import { Loader } from '../../utils/common';
import DataGrid from "g6reactdatagrid";
import { Row, Col,  Button,  Card, CardHeader,  Input, CardBody, Nav, NavItem, NavLink } from "reactstrap";
import moment from 'moment';

import Constants from '../../config/constants';  

import EditAppSetting from './editAppSetting';  
import AddAppSetting from './addAppSetting';  


import AddAppService from './addAppServices';  
import UpdateAppService from './updateAppServices';  

import AddAppUtility from './addAppUtility';  
import UpdateAppUtility from './updateAppUtility';  

import BackgroundMusic from '../backgroundMusic';  
import SiriShortcuts from '../siriShortcuts';  
import SystemLanguage from '../languageManagement/SystemLanguage';  


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit } from '@fortawesome/free-solid-svg-icons'

import CheckPasswordPopup from '../../shared/checkPasswordPopup';



class Settings extends Component {
  constructor(props) {
    super(props);
    this.closeModal = this.closeModal.bind(this)
    this.updateSetting = this.updateSetting.bind(this)
    this.addAppSetting = this.addAppSetting.bind(this)
    this.state = {
      appFeatures : [],
      settings : [],
      openAddAppSettingModal : false,
      openEditAppSettingModal : false,
      selectedRow : {},
      selectedServiceRow : {},
      
      addAppSettingPopupError : "",
      updateSettingPopupError : "",
      
      openCheckPasswordModal : false,
      
      openAddAppServiceModal : false,
      addAppServicePopupError : "",

      openUpdateAppServiceModal : false,
      updateAppServicePopupError : "",


      openAddAppUtilityModal : false,
      addAppUtilityPopupError : "",

      openUpdateAppUtilityModal : false,
      updateAppUtilityPopupError : "",
      getData : false,




      services : [],
      serviceLimit : 100,
      selectedServiceRow : {},
      serviceOffset : 0,
      totalServices : 0,
      servicePage : 0,

      utilities : [],
      utilityLimit : 100,
      selectedUtilityRow : {},
      utilityOffset : 0,
      totalutilities : 0,
      utilityPage : 0,
      getSiriShortcuts: false
    }

    this.columns = [
      { "columnName": "title", "displayName": "Title", sort : false},
      // { "columnName": "subscription", "displayName": "Subscription", sort : false},
      // { "columnName": "createdDate", "displayName": "Created On", sort : false},
    ];

    this.settingsColumns = [
      { "columnName": "title", "displayName": "Title", sort : false},
      { "columnName": "value", "displayName": "Value", sort : false},
      // { "columnName": "createdDate", "displayName": "Created On", sort : false},
    ];


    this.serviceColumns = [
      { "columnName": "serviceName", "displayName": "Service", sort : false},
      { "columnName": "apiKey", "displayName": "Api Key", sort : false},
      { "columnName": "domain", "displayName": "Domain", sort : false},
    ];


    this.utilityColumns = [
      { "columnName": "title", "displayName": "Title", sort : false},
      { "columnName": "value", "displayName": "Value", sort : false},
      { "columnName": "displayType", "displayName": "Type", sort : false},
    ];
  }

  componentDidMount() {
    if (process.env.REACT_APP_CHECK_PASSWORD === 'ON') {
      this.setState({openCheckPasswordModal : true});
    }else {
      this.getLatestAppFeatures();
      this.getAllSettings();
      this.getAllServices();
      this.getAllUtilities();
      this.setState({getData : true, getSiriShortcuts : true})
    }
  }



  componentWillReceiveProps(nextProps) {
    let self = this;
    let appFeatures = [];
    Loader(nextProps.requestProcess);
    if (nextProps.getAppFeatures && nextProps.getAppFeatures.statusCode === 200) {
      if (nextProps.getAppFeatures && nextProps.getAppFeatures.result) {
        nextProps.getAppFeatures.result.map(function (element, key) {
          if (element.slug !== 'POINTS') {
            element.createdDate = moment(element.createdDate).format("DD-MMM-YYYY");
            appFeatures.push(element);
          }
        });
      }

      self.setState({appFeatures : appFeatures});
    }

    if (nextProps.settings && nextProps.settings.statusCode === 200 && nextProps.settings.result ) {
      nextProps.settings.result.map(function (element, key) {
        nextProps.settings.result[key].createdDate = moment(element.createdDate).format("DD-MMM-YYYY")
      });

      self.setState({settings : nextProps.settings.result});
    }

    if ((nextProps.updatedSetting && nextProps.updatedSetting.statusCode === 200 && nextProps.updatedSetting.result) || nextProps.addedSetting && nextProps.addedSetting.statusCode === 200 && nextProps.addedSetting.result ) {
      this.getAllSettings();
      this.closeModal();
    }

    if (nextProps.addedSetting && nextProps.addedSetting.statusCode !== 200) {
      this.setState({addAppSettingPopupError : nextProps.addedSetting.data.statusDescription});  
    }


    if (nextProps.updatedSetting && nextProps.updatedSetting.statusCode !== 200) {
      this.setState({updateSettingPopupError : nextProps.updatedSetting.data.statusDescription});  
    }


    if (nextProps.serviceData && nextProps.serviceData.statusCode === 200) {
      if (nextProps.serviceData.result && nextProps.serviceData.result.services && nextProps.serviceData.result.services.length) {
        nextProps.serviceData.result.services.map(function(service, index){
          nextProps.serviceData.result.services[index].createdDate = moment(service.createdDate).format("DD-MMM-YYYY")
          nextProps.serviceData.result.services[index].modifiedDate = moment(service.modifiedDate).format("DD-MMM-YYYY")
        })
      }
      self.setState({services : (nextProps.serviceData.result && nextProps.serviceData.result.services) ? nextProps.serviceData.result.services : [], totalServices : nextProps.serviceData.result.totalRecords});
    }

    if (nextProps.addService && nextProps.addService.statusCode === 200 && nextProps.addService.result ) {
      this.getAllServices();
      this.closeModal();
    }

    if (nextProps.addService && nextProps.addService.data && nextProps.addService.data.statusCode !== 200) {
      this.setState({addAppServicePopupError : nextProps.addService.data.statusDescription});  
    }


    if (nextProps.updateService && nextProps.updateService.statusCode === 200 && nextProps.updateService.result ) {
      this.getAllServices();
      this.closeModal();
    }

    if (nextProps.updateService && nextProps.updateService.data && nextProps.updateService.data.statusCode !== 200) {
      this.setState({addAppServicePopupError : nextProps.updateService.data.statusDescription});  
    }

    if (nextProps.utilityData && nextProps.utilityData.statusCode === 200) {
      if (nextProps.utilityData.result && nextProps.utilityData.result.utilities && nextProps.utilityData.result.utilities.length) {
        nextProps.utilityData.result.utilities.map(function(utility, index){
          nextProps.utilityData.result.utilities[index].createdDate = moment(utility.createdDate).format("DD-MMM-YYYY")
          nextProps.utilityData.result.utilities[index].modifiedDate = moment(utility.modifiedDate).format("DD-MMM-YYYY")
          nextProps.utilityData.result.utilities[index]['displayType'] = self.formatTitlePosLabel(utility.type)
        })
      }
      self.setState({
        utilities : (nextProps.utilityData.result && nextProps.utilityData.result.utilities) ? nextProps.utilityData.result.utilities : [],
        totalutilities : nextProps.utilityData.result.totalRecords});
    }

    if (nextProps.addUtility && nextProps.addUtility.statusCode === 200 && nextProps.addUtility.result ) {
      this.getAllUtilities();
      this.closeModal();
    }

    if (nextProps.addUtility && nextProps.addUtility.data && nextProps.addUtility.data.statusCode !== 200) {
      this.setState({addAppUtilityPopupError : nextProps.addUtility.data.statusDescription});  
    }


    if (nextProps.updateUtility && nextProps.updateUtility.statusCode === 200 && nextProps.updateUtility.result ) {
      this.getAllUtilities();
      this.closeModal();
    }

    if (nextProps.updateUtility && nextProps.updateUtility.data && nextProps.updateUtility.data.statusCode !== 200) {
      this.setState({addAppUtilityPopupError : nextProps.updateUtility.data.statusDescription});  
    }
  }



  formatTitlePosLabel (s) {
    if (typeof s !== 'string') return ''
      s = s.replace("_", " ");
      s = s.charAt(0).toUpperCase() + s.slice(1).toLowerCase();
      return s;
  }
  

  getLatestAppFeatures() {
    this.props._getAppFeatures({});
  }

  getAllSettings() {
    this.props._getAllSettings({});
  }

  getAllServices() {
    let params = {
      limit : this.state.serviceLimit,
      offset : this.state.serviceOffset,
    }
    this.props._getAllServices(params);
  }


  getAllUtilities() {
    let params = {
      limit : this.state.utilityLimit,
      offset : this.state.utilityOffset,
    }
    this.props._getAllUtilities(params);
  }

  updateSettingModal(rowData) {
    this.setState({
      selectedRow : rowData,
      openEditAppSettingModal : true
    })
  }


  addSettingModal(rowData) {
    this.setState({
      openEditAppSettingModal : true,
      selectedRow : rowData
    })
  }


  updateAppServiceModal(rowData) {
    this.setState({
      selectedServiceRow : rowData,
      openUpdateAppServiceModal : true
    })
  }



  addAppSettingModal(rowData) {
    this.setState({
      openAddAppSettingModal : true,
      addAppSettingPopupError : ""
    })
  }



  addAppUtilitiesModal(rowData) {
    this.setState({
      openAddAppUtilityModal : true,
      addAppUtilityPopupError :""
    })
  }



  updateAppUtilityModal(rowData) {
    this.setState({
      openUpdateAppUtilityModal : true,
      updateAppUtilityPopupError :"",
      selectedUtilityRow : rowData
    })
  }


  addAppServiceModal(rowData) {
    this.setState({
      openAddAppServiceModal : true,
      addAppServicePopupError : ""
    })
  }



  

  handleChange(event) {
    let target = event.target;
    let name = target.name;
    let value;
    if (target.type === 'checkbox') {
      value = (this.state[name]) ? false : true
    }else {
      value = target.value;
    }
    this.setState({[name]: value});
  }

  changeSubscription(event, rowData) {
    let subsObj = {
      featureId : rowData['_id'],
      subscription : (event.target.checked) ? Constants.PAID : Constants.FREE,
    }
    // calling api for change subscription
    this.props._updateFeature(subsObj);
  }

  
  updateSetting(event, settingObj) {
    this.props._updateSetting(settingObj);
    this.setState({updateSettingPopupError : ""});
    event.preventDefault();
  }


  addAppSetting(event, settingObj) {
    this.props._addSetting(settingObj);
    this.setState({addAppSettingPopupError : ""});
    event.preventDefault();
  }



  addAppUtility(event, utilsObj) {
    this.props._addAppUtility(utilsObj);
    this.setState({addAppUtilityPopupError : ""});
    event.preventDefault();
  }


  addAppservice(event, serviceObj) {
    this.props._addService(serviceObj);
    this.setState({addAppServicePopupError : ""});
    event.preventDefault();
  }

  updateAppService(event,serviceObj) {
    this.props._updateService(serviceObj);
    this.setState({updateAppServicePopupError : ""});
    event.preventDefault();
  }


  updateAppUtility(event,utilityObj) {
    this.props._updateUtility(utilityObj);
    this.setState({updateAppUtilityPopupError : ""});
    event.preventDefault();
  }


  closeModal(redirect) {
    if (redirect) {
      this.props.router.push('parent');
    }
    
    this.setState({
      openEditAppSettingModal: false,
      openAddAppSettingModal: false,
      selectedRow : {},
      openCheckPasswordModal : false,
      openAddAppServiceModal : false,
      openUpdateAppServiceModal : false,
      openAddAppUtilityModal : false,
      openUpdateAppUtilityModal : false,
    });
  }


  isPasswordMatched(matched) {
    if (matched) {
      this.getLatestAppFeatures();
      this.getAllSettings();
      this.getAllServices();
      this.getAllUtilities();

      this.closeModal(false);

      this.setState({getData : true, getSiriShortcuts : true})


    }
  }



  render() {
    let self = this;
    let { appFeatures, settings, openEditAppSettingModal, selectedRow, openAddAppSettingModal, addAppSettingPopupError, updateSettingPopupError, openCheckPasswordModal, services, openAddAppServiceModal, openUpdateAppServiceModal, addAppServicePopupError, totalServices, servicePage, serviceLimit, serviceOffset, updateAppServicePopupError, selectedServiceRow, utilities, utilityLimit, utilityOffset, utilityPage, totalutilities, openAddAppUtilityModal, addAppUtilityPopupError, openUpdateAppUtilityModal, updateAppUtilityPopupError, getData, getSiriShortcuts } = this.state;

    let totalRecordsLabel = '##TOTAL_RECORDS## '+('Features')
    let featuresOptions = {
      totalRecords: appFeatures.length,
      totalRecordsLabel: totalRecordsLabel,
      rowsPerPage: 10000,
      page: 0,
      loadMore: false,
      customColumn: {
        isActive: true,
        columnTitle: 'Paid',
        columnContent: (rowData) => {
          return (
            <div>
            <Nav>
            <NavItem>
              <Input type="checkbox" defaultChecked={rowData.subscription === Constants.PAID} onChange={(event) => {this.changeSubscription(event, rowData)}} />{' '}
            </NavItem>
            </Nav>
            </div>
            );
        }
      },

      /*onPageChange(pageNumber) {
        self.state.page = pageNumber;
        self.state.offset = parseInt(pageNumber) * parseInt(self.state.limit)
        self.getAppFeatures(self.state.offset, self.state.limit, self.state.sortField, self.state.sortValue);
      },

      onColumnSortChange: (column, direction) => {
        self.state.sortField = column;
        self.state.sortValue = (direction === "ASC") ? -1 : 1;
        self.getAppFeatures(self.state.offset, self.state.limit, self.state.sortField, self.state.sortValue);
      }*/
    };

    let settingsOptions = {
      totalRecords: settings.length,
      totalRecordsLabel: totalRecordsLabel,
      rowsPerPage: 10000,
      page: 0,
      loadMore: false,
      customColumn: {
        isActive: true,
        columnTitle: 'Update',
        columnContent: (rowData) => {
          return (
            <div>
              <Nav>
                <NavItem>
                  <NavLink href="Javascript:void(0);" onClick={() => { this.updateSettingModal(rowData); }}><FontAwesomeIcon icon={faEdit} size='lg' /> </NavLink>
                </NavItem>
              </Nav>
            </div>
          );
        }
      }
    };

    let servicesTotalRecordsLabel = '##TOTAL_RECORDS## '+('Services')
    let serviceOptions = {
      totalRecords: totalServices,
      totalRecordsLabel: servicesTotalRecordsLabel,
      rowsPerPage: serviceLimit,
      page: servicePage,
      loadMore: false,
      customColumn: {
        isActive: true,
        columnTitle: 'Update',
        columnContent: (rowData) => {
          return (
            <div>
              <Nav>
                <NavItem>
                  <NavLink href="Javascript:void(0);" onClick={() => { this.updateAppServiceModal(rowData); }}><FontAwesomeIcon icon={faEdit} size='lg' /> </NavLink>
                </NavItem>
              </Nav>
            </div>
          );
        },
        onPageChange(pageNumber) {
          /*self.state.servicePage = pageNumber;
          self.state.serviceOffset = parseInt(pageNumber) * parseInt(self.state.limit)
          self.setState({servicePage : pageNumber, serviceOffset : parseInt(pageNumber) * parseInt(self.state.limit)}, () => {
            self.getAllServices();
          })*/
        },
      }
    };

    let utilityTotalRecordsLabel = '##TOTAL_RECORDS## '+('Utilities')
    let utilityOptions = {
      totalRecords: totalutilities,
      totalRecordsLabel: utilityTotalRecordsLabel,
      rowsPerPage: utilityLimit,
      page: utilityPage,
      loadMore: false,
      customColumn: {
        isActive: true,
        columnTitle: 'Update',
        columnContent: (rowData) => {
          return (
            <div>
              <Nav>
                <NavItem>
                  <NavLink href="Javascript:void(0);" onClick={() => { this.updateAppUtilityModal(rowData); }}><FontAwesomeIcon icon={faEdit} size='lg' /> </NavLink>
                </NavItem>
              </Nav>
            </div>
          );
        },
        onPageChange(pageNumber) {
          self.state.utilityPage = pageNumber;
          self.state.utilityOffset = parseInt(pageNumber) * parseInt(self.state.utilityLimit)
          self.setState({utilityPage : pageNumber, utilityOffset : parseInt(pageNumber) * parseInt(self.state.utilityLimit)}, () => {
            self.getAllUtilities();
          })
        },
      }
    };




    return (
      <div className="content">
        <div>
        { openCheckPasswordModal && <CheckPasswordPopup openCheckPasswordModal={openCheckPasswordModal}  closeModal={(resp) => {this.closeModal(resp)}} isPasswordMatched={(resp) => this.isPasswordMatched(resp)}  />} 
        <Row className="pb-4">
          <Col lg={8} sm={12}>
            <h3>Settings</h3>                 
            {/*<Col lg={4} sm={12} className="text-right">
             {
                //<button className="btn btn-primary" color="link" onClick={() => this.addAppSettingModal()}>Add New Setting</button>
             }
           </Col>*/}
          </Col>
        </Row>


        <Row>
          <Col lg={8} sm={12}>

           {/*SERVICES*/}

            {openAddAppServiceModal && <AddAppService openAddAppServiceModal={openAddAppServiceModal}  closeModal={() => this.closeModal(false)} addAppService={(event, serviceObj) => {this.addAppservice(event,serviceObj)}}  addAppServicePopupError={addAppServicePopupError}/>}


            {openUpdateAppServiceModal && <UpdateAppService openUpdateAppServiceModal={openUpdateAppServiceModal}  closeModal={() => this.closeModal(false)} updateAppService={(event, serviceObj) => {this.updateAppService(event,serviceObj)}}  updateAppServicePopupError={updateAppServicePopupError} selectedRow={this.state.selectedServiceRow}/>}

            <Card>
              <CardHeader>
                Services
                <Button className="add-settings" color="primary" size="sm" onClick={() => this.addAppServiceModal()}>Add New</Button>
              </CardHeader>
              <CardBody>
                <div className="dataTable-wrapper pillarList_grid">
                  {<DataGrid
                    columns={this.serviceColumns}
                    options={serviceOptions}
                    rows={ services }
                  />}
                </div> 
              </CardBody>
            </Card>
            {/*SERVICES*/}



          {/*SETTING*/}
            {openAddAppSettingModal && <AddAppSetting openAddAppSettingModal={openAddAppSettingModal}  closeModal={this.closeModal} addAppSetting={(event, settingsObj) => {this.addAppSetting(event,settingsObj)}}  addAppSettingPopupError={addAppSettingPopupError}/>}

            {openEditAppSettingModal && <EditAppSetting openEditAppSettingModal={openEditAppSettingModal}  closeModal={this.closeModal} formData={ selectedRow } updateSetting={(event, settingsObj) => {this.updateSetting(event,settingsObj)}} updateSettingPopupError={updateSettingPopupError} />} 
            <Card>
              <CardHeader>
                Settings
                <Button className="add-settings" color="primary" size="sm" onClick={() => this.addAppSettingModal()}>Add New</Button>
              </CardHeader>
              <CardBody>
                <div className="dataTable-wrapper pillarList_grid">
                  {<DataGrid
                    columns={this.settingsColumns}
                    options={settingsOptions}
                    rows={ settings }
                  />}
                </div> 
              </CardBody>
            </Card>
          {/*SETTING*/}


          </Col>

          {/*Features*/}
          <Col lg={4} sm={12}>
            <Card>
              <CardHeader>Features Settings</CardHeader>
              <CardBody>
                <div className="dataTable-wrapper pillarList_grid">
                  {<DataGrid
                  columns={this.columns}
                  options={featuresOptions}
                  rows={ appFeatures }
                  />}
                </div>
              </CardBody>
            </Card>


          {/*SIRISHORTCUTS*/}
            {getSiriShortcuts && <SiriShortcuts {...this.props} getSiriShortcuts={getSiriShortcuts} />}
          {/*SIRISHORTCUTS*/}


          </Col>
          {/*Features*/}
        </Row>


       




        <Row>
          <Col lg={12} sm={12}>

            {/*UTILS*/}
            {openAddAppUtilityModal && <AddAppUtility openAddAppUtilityModal={openAddAppUtilityModal}  closeModal={this.closeModal} addAppUtility={(event, utilityObj) => {this.addAppUtility(event,utilityObj)}}  addAppUtilityPopupError={addAppUtilityPopupError}/>}

            {openUpdateAppUtilityModal && <UpdateAppUtility openUpdateAppUtilityModal={openUpdateAppUtilityModal}  closeModal={this.closeModal} updateAppUtility={(event, utilityObj) => {this.updateAppUtility(event,utilityObj)}}  updateAppUtilityPopupError={updateAppUtilityPopupError} selectedRow={this.state.selectedUtilityRow}/>}

            <Card>
              <CardHeader>
                Utilities
                <Button className="add-settings" color="primary" size="sm" onClick={() => this.addAppUtilitiesModal()}>Add New</Button>
              </CardHeader>
              <CardBody>
                <div className="dataTable-wrapper pillarList_grid">
                  {<DataGrid
                    columns={this.utilityColumns}
                    options={utilityOptions}
                    rows={ utilities }
                  />}
                </div> 
              </CardBody>
            </Card>
          {/*UTILS*/}

          </Col>
        </Row>

        <Row>
          <Col lg={6} sm={6}>
            <BackgroundMusic {...this.props} />
          </Col>


          <Col lg={6} sm={6}>
            <SystemLanguage getData={getData} />
          </Col>
        </Row>

        </div>
      </div>

      
    );
  }
}

export default Settings;
