import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { createContent, updateContent } from './action';
import { Loader, createRandomStr } from '../../utils/common';
import { Row, Col, Modal, Button, ModalHeader, ModalBody, ModalFooter, FormGroup, Label, Input, Form, Alert, FormText } from "reactstrap";
import SimpleReactValidator from 'simple-react-validator';

import { getPillarCategory } from '../category/action';
import { getPillars } from '../pillar/action';
import { getContentDetails } from './action';
import Constants from '../../config/constants';
import Message from '../../config/message';
import Session from '../../utils/session';
//import S3 from 'aws-s3';
import S3Client from '../../services/aws-s3/S3Client';
import * as _ from 'underscore';
import httpClient from '../../services/http';
import Tags from './Tags';
import VimeoService from '../../services/vimeo';

class AddEditContent extends Component {


  constructor(props) {
    super(props);
    this.formatTitlePosLabel = this.formatTitlePosLabel.bind(this);
    this.validator = new SimpleReactValidator();
    this.contentCategories = []
    this.pillarList = [];
    this.selectedCategoriesList = [];
    // this.layoutCount = {};
    this.layoutImgType = [];
    this.cateLayoutMap = [];
    this.s3path = "";
    this.allCategories = [];
    this.state = {
      contentId: props.formData._id,
      title: [],
      priority: props.formData.priority,
      status: props.formData.status,
      pillarId: props.formData.pillarId,
      categoryId: props.formData.categoryId,
      contentType: props.formData.contentType,
      existingContentType: props.formData.contentType,
      titleColor: props.formData && props.formData.titleColor ? props.formData.titleColor : '#000000',
      thumbTitleColor: props.formData && props.formData.thumbTitleColor ? props.formData.thumbTitleColor : '#000000',
      dialColor: props.formData && props.formData.dialColor ? props.formData.dialColor : '#000000',
      description: props.formData.description,
      fullImage: props.formData.fullImage,
      image: props.formData.image,
      thumb: props.formData.thumb,
      media: props.formData.media,
      titlePosition: (props.formData.titlePosition) ? props.formData.titlePosition : Constants.MIDDLE,
      contentTags: (props.formData.contentTags) ? props.formData.contentTags : [],
      tags: props.formData.tags && props.formData.tags.length > 0 ? props.formData.tags[0] : '',
      mediaUrl: props.formData.mediaUrl,
      file: {},
      filterCategories: [],
      showErrorMsg: false,
      errorMsg: "",
      customValidatorMsg: { media: [], fullImage: "", thumb: "", categoryIds: "" },
      layoutType: "MUSIC",
      bestImgThumbText: "",
      bestImgFullText: "",
      layoutImgType: ["GRID-RT"],
      isImageError: false,
      order: props.formData.order,
      s3UploadedFiles: {},
      cateLayoutMap: [],
      systemLanguages: props.systemLanguages,
      systemMedia: [],
      contentLang: props.contentLang,
      validTitle: [],
      languageArr: [],
      selectedAgeGroup: [],
      ageGroups: props.ageGroups
    };

    if (props.formData._id) {
      props._getContentDetails({ contentId: props.formData._id });
    }
    this.onImgLoad = this.onImgLoad.bind(this);
    const s3Config = {
      bucketName: process.env.REACT_APP_S3_BUCKET,
      dirName: 'pillar/content', /* optional */
      region: process.env.REACT_APP_AWS_DEFAULT_REGION,
      accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
      s3Url: process.env.REACT_APP_AWS_BUCKET_URL, /* optional */
    }

    this.S3Client = new S3Client(s3Config);

  }

  componentDidMount() {
    this.setS3Path();
    this.setTitleValidation();
    this.setLanguageArray();
  }

  setTitleValidation() {
    let self = this
    _.each(self.state.systemLanguages, function (value, index) {
      self.state.languageArr.push(true)
      self.state.validTitle.push(value['contentObj']['title'])
    })

    this.setState({ validTitle: self.state.validTitle, languageArr: self.state.languageArr })
  }


  setLanguageArray() {
    let self = this
    self.state.languageArr = [];
    _.each(self.state.systemLanguages, function (value, index) {
      self.state.languageArr.push(true)
    })


    this.setState({ languageArr: self.state.languageArr })
  }

  setS3Path() {
    this.s3path = (Session.getSessionData('config')['BUCKET_URL']) ? Session.getSessionData('config')['BUCKET_URL'] : "";
  }


  init() {
    let self = this;
    // self.getPillarCategory(null);
  }

  getPillarCategory(selectedPillarId) {
    this.props._getPillarCategory(selectedPillarId);
  }

  convertCurrentImgIntoBase64(url, callback) {
    let xhr = new XMLHttpRequest(url, callback);
    xhr.onload = function () {
      let reader = new FileReader();
      reader.onloadend = function () {
        callback(reader.result);
      }
      reader.readAsDataURL(xhr.response);
    };
    xhr.open('GET', url);
    xhr.responseType = 'blob';
    xhr.send();
  }

  createCategoriesSelectOption(filterCategories) {
    if (!filterCategories) {
      return null;
    }

    if (filterCategories && filterCategories.length) {
      return filterCategories.map(function (obj) { return (<option key={obj._id} value={obj._id} >{obj.title}</option>); })
    }

    return null
  }



  componentWillReceiveProps(nextProps) {
    let self = this;
    Loader(nextProps.requestProcess);
    if (nextProps && nextProps.createContent || nextProps.updateContent || nextProps.contentDetails) {

      if (nextProps.createContent) {
        if (nextProps.createContent.statusCode === 200) {
          setTimeout(() => {
            self.setState({ showErrorMsg: false, errorMsg: "" })
            self.props.closeModal(true);
            self.props.getContent();
          }, 200)
        } else {
          setTimeout(() => {
            self.deleteS3Files();
            self.setState({ showErrorMsg: true, errorMsg: (nextProps['createContent']['data'] && nextProps['createContent']['data']['statusDescription']) ? nextProps['createContent']['data']['statusDescription'] : Constants.SOMETHING_WRONG });
          }, 500)
        }
      }

      if (nextProps.updateContent) {
        if (nextProps.updateContent.statusCode === 200) {
          setTimeout(() => {
            self.setState({ showErrorMsg: false, errorMsg: "" })
            self.props.closeModal(true);
            self.props.getContent();
          }, 200)
        } else {
          setTimeout(() => {
            self.deleteS3Files();
            self.setState({ showErrorMsg: true, errorMsg: (nextProps['updateContent']['data'] && nextProps['updateContent']['data']['statusDescription']) ? nextProps['updateContent']['data']['statusDescription'] : Constants.SOMETHING_WRONG });
          }, 500)
        }
      }

      if (nextProps.contentDetails) {
        if (nextProps.contentDetails.statusCode === 200) {
          let contentObj = nextProps.contentDetails.result;
          let layoutImgType = [];
          this.cateLayoutMap = [];
          let stateParams = {};
          let categoryId = [];
          let ageOption = [];
          // let ageOptionAll = false;
          self.allCategories = contentObj.categories;
          for (let i = 0; i < contentObj.categories.length; i++) {
            let catElement = contentObj.categories[i];

            stateParams['thumb_' + catElement.layoutType] = '';

            if (layoutImgType.indexOf(catElement.layoutType) === -1) {
              layoutImgType.push(catElement.layoutType);
            }
            this.cateLayoutMap.push({ "_id": catElement._categoryId, "layoutType": catElement.layoutType, "image": self.s3path + catElement.image, "imageThumb": self.s3path + catElement.imageThumb });
            categoryId.push(catElement._categoryId);
          }
          if (this.props.formData.ContentAgeMap.length > 0) {
            this.props.formData.ContentAgeMap.map((item) => {
              if (item.title === 'All Ages') {
                ageOption = this.props.ageGroups.filter(function (item) {
                  if (item.title !== 'All Ages') {
                    return item;
                  }
                }).map(function (item) { return { label: item.title, value: item._id } });
              } else {
                ageOption.push({ label: item.title, value: item._ageGroupId });
              }
            })
          }
          this.handleMultiSelectChange(ageOption)

          this.setState({
            title: contentObj.title,
            layoutType: contentObj.layoutType,
            contentType: contentObj.contentType,
            existingContentType: contentObj.contentType,
            titleColor: contentObj.titleColor,
            thumbTitleColor: contentObj.thumbTitleColor,
            dialColor: contentObj.dialColor,
            description: contentObj.description,
            tags: contentObj.tags && contentObj.tags.length > 0 ? contentObj.tags[0] : "FREE",
            layoutImgType: layoutImgType,
            categoryId: categoryId,
            cateLayoutMap: this.cateLayoutMap
          });
          this.validateSelectedCategories(this.cateLayoutMap);

        }
      }
    }
  }

  handleChange(event) {
    let self = this;
    let target = event.target;
    let value;
    let currentCategory = {};

    if (target.type === 'checkbox' && target.name === 'status') {
      value = (target.value === 'Active') ? false : true;
    } else if (target.name === 'categoryId') {
      currentCategory = _.findWhere(this.props.categoryList['result'], { _id: target.value });

      let bestImgThumbText = ""
      let bestImgFullText = ""
      if (!_.isEmpty(currentCategory)) {
        if (currentCategory['layoutType'] === Constants.GRID_SQ) {
          bestImgThumbText = Message.SIZE_474_405;
          bestImgFullText = Message.SIZE_990_900;
        } else if (currentCategory['layoutType'] === Constants.GRID_RT) {
          bestImgThumbText = Message.SIZE_474_564;
          bestImgFullText = Message.SIZE_1125_2001;
        } else if (currentCategory['layoutType'] === Constants.LIST) {
          bestImgThumbText = Message.SIZE_252_228;
          bestImgFullText = Message.SIZE_990_900;
        } else {
          bestImgThumbText = ""
          bestImgFullText = ""
        }
      }
      self.state.bestImgThumbText = bestImgThumbText
      self.state.bestImgFullText = bestImgFullText
      value = target.value
    }
    else {
      value = target.value;
    }
    /*if(target.name === 'contentType'){
      this.validateMediaType(target.value, this.state.media)
    }*/
    const name = target.name;
    this.setState({ [name]: value });
  }




  /* uploadFiles(files, callback) {
    let self = this;
    let responseObj = {};
    // let err = null;
    let progressIndex = 0;

    self.S3Client.getPolicy().then((policyRes) => {
      httpClient.call('PILLAR', '/get_signature', { policy: policyRes }, { method: 'GET', headers: {} }).then((signatureRes) => {
        if (signatureRes && signatureRes.statusCode === 200 && signatureRes.result && signatureRes.result.signature) {

          _.each(files, function (fileObj, index) {
            if (fileObj.file instanceof File) {
              createRandomStr((randString) => {

                let signature = signatureRes.result.signature;
                let filename = fileObj.folder + '/' + randString;
                let ext = fileObj.file.name.split('.')[1];

                self.S3Client.uploadFile(fileObj.file, filename + '.' + ext, signature).then(data => {
                  responseObj[fileObj.key] = data.key;
                  responseObj['videoFilePath'] = self.S3Client.config.dirName + '/media/' + randString + '/' + randString + '.m3u8';
                  progressIndex = progressIndex + 1;
                  if (progressIndex === files.length) {
                    callback(responseObj, null)
                  }
                }).catch(err => {
                  console.error(err)
                  return callback(null, err);
                })

              });
            } else {
              responseObj[fileObj.key] = fileObj.file;
              progressIndex = progressIndex + 1;
              if (progressIndex === files.length) {
                callback(responseObj, null)
              }
            }
          });

        } else {
          console.log('ERROR:- SIGNATURE RESPONSE ', signatureRes);
          callback(null, signatureRes.statusDescription)
        }

      }, (error) => {
        console.log('ERROR:- SIGNATURE REQUEST ', error);
        callback(null, error)
      })
    }, (error) => {
      console.log('ERROR:- SIGNATURE REQUEST ', error);
      callback(null, error)
    });
  } */

  uploadFiles(files, callback) {

    let self = this;
    let responseObj = {};
    let progressIndex = 0;
    self.S3Client.getPolicy().then((policyRes) => {
      httpClient.call('PILLAR', '/get_signature', { policy: policyRes }, { method: 'GET', headers: {} }).then((signatureRes) => {
        if (signatureRes && signatureRes.statusCode === 200 && signatureRes.result && signatureRes.result.signature) {
          _.each(files, function (fileObj, index) {
            let operations = {};
            if (fileObj.file instanceof File) {
              createRandomStr((randString) => {
                let signature = signatureRes.result.signature;
                let filename = fileObj.folder + '/' + randString;
                let ext = fileObj.file.name.split('.').pop();
                if (fileObj.folder == 'media' || fileObj.folder == "media/original-video") {
                  // UPLOADING MEDIA
                  VimeoService.uploadVideo({ file: fileObj.file, name: randString, description: '' }, function (percent) {
                    // console.log('percent', percent)
                  }).then(response => {
                    responseObj[fileObj.vimeoKey] = response
                    progressIndex = progressIndex + 1;
                    if (progressIndex === files.length) {
                      callback(responseObj, null)
                    }
                  }, error => {
                    callback(null, error)
                  })

                } else {
                  // UPLOADING IMAGES
                  self.S3Client.uploadFile(fileObj.file, filename + '.' + ext, signature).then(data => {
                    responseObj[fileObj.key] = data.key;
                    progressIndex = progressIndex + 1;
                    if (progressIndex === files.length) {
                      callback(responseObj, null)
                    }
                  }).catch(error => {
                    callback(null, error)
                  })
                }
              });
            } else {
              responseObj[fileObj.key] = fileObj.file;
              progressIndex = progressIndex + 1;
              if (progressIndex === files.length) {
                callback(responseObj, null)
              }
            }
          });
        } else {
          console.log('ERROR:- SIGNATURE RESPONSE ', signatureRes);
          callback(null, signatureRes.statusDescription)
        }

      }, (error) => {
        console.log('ERROR:- SIGNATURE REQUEST ', error);
        callback(null, error)
      })
    }, (error) => {
      console.log('ERROR:- SIGNATURE REQUEST ', error);
      callback(null, error)
    });
  }

  uploadFilesToServers(files) {
    let self = this;
    // let hasError = false;

    return new Promise((resolve, reject) => {
      if (files.length > 0) {
        Loader(true);
        self.uploadFiles(files, function (responseObj, err) {
          // if any of the file processing produced an error, err would equal that error
          if (err) {
            // One of the iterations produced an error.
            // All processing will now stop.
            console.log('A file failed to process');
            Loader(false);
            reject('File upload failed. Please try again.')
          } else {
            resolve(responseObj);
            console.log('All files have been processed successfully');
          }

        })
      } else {
        resolve({});
        Loader(false);
      }

    })
  }

  deleteS3Files() {
    let { s3UploadedFiles } = this.state;
    let self = this;
    if (Object.keys(s3UploadedFiles).length) {
      _.map(s3UploadedFiles, function (filePath, key) {
        filePath = filePath.replace('pillar/content/', '');
        self.S3Client.deleteFile(filePath)
          .then(response => console.log(response))
          .catch(err => console.error(err))
        self.setState({ s3UploadedFiles: {} })
      });
    }
  }

  appendCatLayoutImage(catelayoutMap, uploadedFileRes, cb) {
    let categorylayout = []
    if (catelayoutMap && catelayoutMap.length) {
      _.each(catelayoutMap, function (value, index) {

        categorylayout.push({
          _id: value['_id'],
          layoutType: value['layoutType'],
          thumb: uploadedFileRes['thumb_' + value['layoutType']]
        })
      })
    }
    cb(categorylayout)
  }

  createSelectOption_old() {
    let priorities = [
      { value: 1, displayValue: "High" },
      { value: 2, displayValue: "Medium" },
      { value: 3, displayValue: "Low" }
    ];
    return priorities.map(function (obj) { return (<option key={obj.value} value={obj.value} >{obj.displayValue}</option>); })
  }


  createContentTypeOption() {
    let priorities = [
      { value: "AUDIO", displayValue: "Audio" },
      { value: "EPUB", displayValue: "Epub" },
      { value: "VIDEO", displayValue: "Video" },
      { value: "IMAGE", displayValue: "Image" },
    ];
    return priorities.map(function (obj) { return (<option key={obj.value} value={obj.value} >{obj.displayValue}</option>); })
  }


  createStatusOption() {
    let priorities = [
      { value: "PUBLISHED", displayValue: "Published" },
      { value: "UNPUBLISH", displayValue: "Unpublish" },
    ];
    return priorities.map(function (obj) { return (<option key={obj.value} value={obj.value} >{obj.displayValue}</option>); })
  }

  createAgeGroupOption() {
    const option = this.state.ageGroups.filter(function (item) {
      if (item.title !== 'All Ages') {
        return item;
      }
    }).map(function (item) { return { label: item.title, value: item._id } });
    const priorities = [
      {
        label: "All Ages",
        options: option
      },
    ];
    return priorities;
  }

  handleMultiSelectChange(event) {
    //console.log(event)
    this.setState({ selectedAgeGroup: event });

  }
  setImg(event, layoutVal, index) {
    let self = this;
    let target = event.currentTarget;
    let file = event.target.files[0]; // FileList object


    let img = document.createElement("img");
    self.state.backgroundImageFile = file;
    self.validateImageType(target.name, file)
    img.onload = function () {
      let customValidatorMsg = self.state.customValidatorMsg;

      if (layoutVal === Constants.GRID_SQ && (this.width > 474 || this.height > 405)) {

        customValidatorMsg['thumb_' + layoutVal] = Message.SIZE_474_405_error;
        self.setState({ customValidatorMsg: customValidatorMsg, ['thumb_' + layoutVal + '_error']: true });
      } else if (layoutVal === Constants.GRID_RT && (this.width > 474 || this.height > 564)) {

        customValidatorMsg['thumb_' + layoutVal] = Message.SIZE_474_564_error;
        self.setState({ customValidatorMsg: customValidatorMsg, ['thumb_' + layoutVal + '_error']: true });
      } else if (layoutVal === 'fullImage' && (this.width > 1125 || this.height > 2001)) {

        customValidatorMsg['fullImage'] = Message.SIZE_1125_2001_error;
        self.setState({ customValidatorMsg: customValidatorMsg, ['thumb_' + layoutVal + '_error']: true });
      } else {

        if (layoutVal === 'fullImage') {
          customValidatorMsg['fullImage'] = '';
        } else {
          customValidatorMsg['thumb_' + layoutVal] = ''
        }

        let updateState = {
          [target.name]: file,
          customValidatorMsg: customValidatorMsg,
          ['thumb_' + layoutVal + '_error']: false
        };

        self.setState(updateState)
      }
    };

    let reader = new FileReader();
    reader.onloadend = function (ended) {
      img.src = ended.target.result;

      if (self.cateLayoutMap && self.cateLayoutMap.length) {
        _.each(self.cateLayoutMap, function (value, index) {
          if (self.cateLayoutMap[index]['layoutType'] === layoutVal) {
            self.cateLayoutMap[index]['imageThumb'] = ended.target.result
          }

          if (layoutVal === Constants.FULL_IMAGE) {
            self.cateLayoutMap[index]['image'] = ended.target.result
          }
        })
        self.setState();
      }
    }
    reader.readAsDataURL(file);


  }

  setMedia(event) {
    let self = this;
    let file = event.target.files[0]; // FileList object
    this.validateMediaType(this.state.contentType, file);
    self.setState({ media: file })
  }

  validateSelectedCategories(category) {
    let self = this;
    let currentCustomValidatorMsg = self.state.customValidatorMsg;
    if (_.isEmpty(category)) {
      currentCustomValidatorMsg['categoryIds'] = 'Please select atleast one category.';
      self.setState({ CustomValidatorMsg: currentCustomValidatorMsg });
      return false;
    } else {
      currentCustomValidatorMsg['categoryIds'] = '';
      self.setState({ CustomValidatorMsg: currentCustomValidatorMsg });
      return true;
    }
  }





  validateImageType(name, file) {
    let self = this;
    let currentCustomValidatorMsg = self.state.customValidatorMsg;
    let { existingContentType, contentType } = this.state;
    if (file) {
      if (['image/jpeg', 'image/png'].indexOf(file.type) === -1) {
        currentCustomValidatorMsg[name] = 'Please select valid image(.jpg, .png) file.';
        self.setState({ customValidatorMsg: currentCustomValidatorMsg });
        return false;
      } else {
        currentCustomValidatorMsg[name] = '';
        self.setState({ customValidatorMsg: currentCustomValidatorMsg });
        return true;
      }
    } else if (name === 'fullImage' && contentType === 'IMAGE' && existingContentType !== contentType && !file) {
      currentCustomValidatorMsg[name] = 'The full image field is required.';
      self.setState({ customValidatorMsg: currentCustomValidatorMsg });
      return false;
    } else if (!file && this.state.contentType === "GAME") {
      currentCustomValidatorMsg[name] = '';
      self.setState({ customValidatorMsg: currentCustomValidatorMsg });
      return true;
    } else {
      // currentCustomValidatorMsg[name] = `The ${name} field is required.`;
      // self.setState({CustomValidatorMsg: currentCustomValidatorMsg});
      return true;
    }
  }



  createSelectOption(categories) {
    // let self = this
    const { pillarList } = this.props;
    const { categoryId } = this.state;
    if (!categories) {
      return null;
    }

    if (categories && categories.length > 0 && pillarList && pillarList.length > 0) {
      return pillarList.map(function (obj) {
        let filterCategories = _.where(categories, { pillarId: obj._id });
        return <optgroup key={obj._id} label={obj.title}>{filterCategories && filterCategories.map(function (obj) {
          return <option selected={categoryId.indexOf(obj._id) !== -1 ? "selected" : false} key={obj._id + '1'} value={obj._id} >{obj.title}</option>
        })}</optgroup>
      })
    }
    //return <option selected={categoryId.indexOf(obj._id) != -1 ? true : false} value={obj._id} key={obj._id} value={obj._id} >{obj.title}</option> 

    return null
  }


  toggleCategories(obj) {
    if (_.findWhere(this.selectedCategoriesList, { _id: obj['_id'] })) {
      this.selectedCategoriesList.splice(obj, 1)
    } else {
      this.selectedCategoriesList.push(obj)
    }

  }

  createPillarsSelectOption() {
    this.pillarList = this.props.pillarList;
    if (_.isEmpty(this.pillarList)) {
      return null;
    } else {
      return this.pillarList.map(function (obj) { return (<option key={obj._id} value={obj._id} >{obj.title}</option>); })
    }
  }

  onSelectCategory(obj) {
    let self = this;
    let { categoryList } = this.props;
    //console.log('categoryList', categoryList, obj.target.options, self.cateLayoutMap, this.state.cateLayoutMap);
    if (obj.target.options.length) {
      for (let i = 0, len = obj.target.options.length; i < len; i++) {
        let opt = obj.target.options[i];
        if (opt.selected) {
          let exist = _.findWhere(self.cateLayoutMap, { _id: opt.value });
          if (!exist) {
            let category = _.findWhere(categoryList.result, { _id: opt.value });
            if (category) {
              let lastCategory = _.findWhere(self.state.cateLayoutMap, { layoutType: category.layoutType });
              let image = "";
              let imageThumb = "";
              if (lastCategory) {
                if (lastCategory['image']) {
                  image = lastCategory['image']
                }

                if (lastCategory['imageThumb']) {
                  imageThumb = lastCategory['imageThumb']
                }
              }

              self.cateLayoutMap.push({ "_id": opt.value, "layoutType": category.layoutType, image: image, imageThumb: imageThumb });
              if (self.layoutImgType.indexOf(category.layoutType) === -1) {
                self.layoutImgType.push(category.layoutType);
              }
            }
          }
        }
        else {
          let category = _.findWhere(self.cateLayoutMap, { _id: opt.value })
          if (category && !_.isEmpty(category)) {
            self.cateLayoutMap = _.without(self.cateLayoutMap, category);
            if (_.findWhere(self.cateLayoutMap, { layoutType: category['layoutType'] }) === undefined) {
              self.layoutImgType.splice(self.layoutImgType.indexOf(category['layoutType']), 1);
            }
          }
        }
      }
    } else {
      this.cateLayoutMap = []
    }


    if (this.cateLayoutMap && this.cateLayoutMap.length === 0) {
      this.setState({
        media: "",
        fullImage: "",
        customValidatorMsg: { media: "", fullImage: "", thumb: "", categoryIds: "" },
      })
    }


    this.validateSelectedCategories(this.cateLayoutMap)
    if (self.layoutImgType.length === 0) {
      self.layoutImgType = ["GRID-RT"];
    }

    this.setState({ "layoutImgType": self.layoutImgType });
  }

  onImgLoad(loadEvent, name) {
    // console.log*('loadEvent, name', loadEvent, name);
  }

  filesSection() {
    let self = this;
    // let { layoutImgType } = this.state;
    let htmltemplate = [];
    let layoutArr = []
    _.each(self.cateLayoutMap, function (value, i) {
      if (!_.contains(layoutArr, value['layoutType'])) {
        let layoutVal = value['layoutType'];
        let bestImgThumbText = ""
        let bestImgFullText = ""
        let layoutName = ""

        if (layoutVal === Constants.GRID_SQ) {
          layoutName = "For Square Grid Layout"
          bestImgThumbText = Message.SIZE_474_405;
          bestImgFullText = Message.SIZE_1125_2001;

        } else if (layoutVal === Constants.GRID_RT) {
          layoutName = "For Rectangle Grid Layout"
          bestImgThumbText = Message.SIZE_474_564;
          bestImgFullText = Message.SIZE_1125_2001;

        } else if (layoutVal === Constants.LIST) {
          layoutName = "For List Layout"
          bestImgThumbText = Message.SIZE_252_228;
          bestImgFullText = Message.SIZE_990_900;
        }

        htmltemplate.push(
          <Col key={i}>
            <FormGroup>
              <Label for="thumb">{layoutName} Thumb</Label>
              <Input type="file" name={'thumb_' + layoutVal} id={'thumb_' + layoutVal} onChange={(event) => self.setImg(event, layoutVal, i)} />
              {(bestImgThumbText) ?
                <FormText color="muted">
                  {bestImgThumbText}
                </FormText>

                : null}

              <div className="validation-error">{self.state.customValidatorMsg['thumb_' + layoutVal] && <div className="srv-validation-message">{self.state.customValidatorMsg['thumb_' + layoutVal]}</div>}</div>
              {(self.cateLayoutMap && self.cateLayoutMap[i] && self.cateLayoutMap[i]['imageThumb']) ? <div className="add-edit-thumb"><img src={self.cateLayoutMap[i]['imageThumb']} /></div> : null}
            </FormGroup>
          </Col>
        )
        layoutArr.push(value['layoutType']);
      }
    })


    /*for(let i = 0; i < layoutImgType.length; i++) {
    }*/

    {
      (this.state.contentType === Constants.AUDIO || this.state.contentType === Constants.IMAGE || this.state.contentType === Constants.GAME) &&
        htmltemplate.push(
          <Col key={'fullimage'}>
            <FormGroup>
              <Label for="fullImage">Full Image</Label>
              <Input type="file" name={'fullImage'} id="fullImage" onChange={(event) => self.setImg(event, Constants.FULL_IMAGE, 0)} />
              <FormText color="muted">
                {this.state.layoutType !== Constants.MUSIC || this.state.contentType === 'IMAGE' ? Message.SIZE_1125_2001 : Message.SIZE_990_900}
              </FormText>

              <div className="validation-error">{self.state.customValidatorMsg['fullImage'] && <div className="srv-validation-message">{self.state.customValidatorMsg['fullImage']}</div>}</div>
              {(self.cateLayoutMap && self.cateLayoutMap[0] && self.cateLayoutMap[0]['image']) ? <div className="add-edit-thumb"><img src={self.cateLayoutMap[0]['image']} /></div> : null}
            </FormGroup>
          </Col>)
    }
    return htmltemplate;
  }

  formatTitlePosLabel(s) {
    if (typeof s !== 'string') return ''
    s = s.replace("_", " ");
    s = s.charAt(0).toUpperCase() + s.slice(1).toLowerCase();
    return s;
  }

  setTitle(event, index, languageSlug) {
    this.state.systemLanguages[index]['contentObj'][event.target.name] = event.target.value;
    this.state.validTitle[index] = event.target.value;
    this.state.systemLanguages[index]['contentObj']['languageSlug'] = languageSlug;
    this.setState({ systemLanguages: this.state.systemLanguages, title: this.state.title })
  }

  SectionsToRender() {
    let self = this;
    return self.state.systemLanguages.map(function (obj, index) {
      return (
        (self.state.languageArr && self.state.languageArr[index]) ? <div key={obj.slug}>


          <Row>
            <Col xs="12">
              <FormGroup>
                <Label htmlFor={obj.title + '_title'}>{obj.title} Title {self.state.contentType === 'IMAGE' && <span>(optional)</span>}</Label>
                <Input type="text" id={obj.title + '_title'} placeholder="Title" name="title" value={(obj.contentObj && obj.contentObj['title']) ? obj.contentObj['title'] : ""} onChange={(event) => { self.setTitle(event, index, obj.slug) }} />
                {self.state.validTitle && self.state.contentType !== Constants.IMAGE &&
                  <div className="validation-error">{self.validator.message(obj.title + ' title', self.state.validTitle[index], 'required')}</div>
                }
              </FormGroup>
            </Col>
          </Row>

          <Row>
            <Col xs="12">
              <FormGroup>
                <Label htmlFor="description">{obj.title} Description</Label>
                <Input type="textarea" id="description" placeholder="Description" name="description" style={{ minHeight: '189px' }} value={(obj.contentObj && obj.contentObj['description']) ? obj.contentObj['description'] : ""} onChange={(event) => { self.setTitle(event, index, obj.slug) }} />

              </FormGroup>
            </Col>
          </Row>
        </div> : null
      );
    })
  }



  MediaToRender() {
    let self = this;
    return self.state.systemLanguages.map(function (obj, index) {
      return (
        (self.state.languageArr && self.state.languageArr[index]) ? <Col xs="3" key={obj.slug}>
          <FormGroup>
            <Label for={obj.title}>{obj.title} Media</Label>

            <Input type="file" name="media" id={obj.title} onChange={(event) => { self.setLanguageMedia(event, index, obj.slug) }} />

            {self.state.customValidatorMsg.media && self.state.customValidatorMsg.media[index] && <div className="validation-error"><div className="srv-validation-message">{self.state.customValidatorMsg.media[index]}</div></div>}
          </FormGroup>
          {obj.contentObj && obj.contentObj.mediaUrl &&
            <a href={self.s3path + obj.contentObj.mediaUrl} target="_blank" rel="noreferrer">View uploaded media</a>
          }
        </Col> : null
      );
    })
  }



  setLanguageMedia(event, index, languageSlug) {
    let self = this;
    let file = event.target.files[0]; // FileList object

    this.validateMediaType(this.state.contentType, file, index);

    if (this.state.systemMedia[index]) {
      this.state.systemMedia[index] = {
        file: file,
        languageSlug: languageSlug
      }
    } else {
      this.state.systemMedia[index] = {};
      this.state.systemMedia[index] = {
        file: file,
        languageSlug: languageSlug
      }
    }

    self.setState({ systemMedia: this.state.systemMedia })
  }



  validateMediaType(contentType, file, index) {
    let self = this;
    let currentCustomValidatorMsg = self.state.customValidatorMsg;
    let validFileType = {
      'AUDIO': 'MP3, WAV, OGG, AAC, M4A, WMA, ',
      'VIDEO': 'MP4, AVI, WEBM',
      'EPUB': 'EPUB',
      'IMAGE': 'JPG, PNG'
    }
    let existingContentType = this.state.existingContentType;

    if (contentType === 'AUDIO' || contentType === 'EPUB' || contentType === 'VIDEO') {
      if (file) {
        if ((contentType === 'AUDIO' && (file.type !== 'audio/mp3' && file.type !== 'audio/mpeg' && file.type !== 'audio/wav' && file.type !== 'audio/ogg' && file.type !== 'audio/aac' && file.type !== 'audio/x-m4a' && file.type !== 'audio/x-ms-wma')) ||
          (contentType === 'EPUB' && file.type !== 'application/epub+zip') ||
          (contentType === 'VIDEO' && file.type !== 'video/mp4' && file.type !== 'video/webm' && file.type !== 'video/x-msvideo')) {

          currentCustomValidatorMsg['media'][index] = 'Please select valid file. Valid file type is ' + validFileType[contentType];
          self.setState({ CustomValidatorMsg: currentCustomValidatorMsg });
          return false;
        } else {
          currentCustomValidatorMsg['media'][index] = '';
          self.setState({ CustomValidatorMsg: currentCustomValidatorMsg });
          return true;
        }
      } else if (this.state.systemLanguages[index] && this.state.systemLanguages[index]['contentObj'] && this.state.systemLanguages[index]['contentObj']['mediaUrl'] && existingContentType && existingContentType === contentType) {
        currentCustomValidatorMsg['media'][index] = '';
        self.setState({ CustomValidatorMsg: currentCustomValidatorMsg });

        return true;
      } else if (existingContentType && existingContentType !== contentType) {

        currentCustomValidatorMsg['media'][index] = 'The media field is required.';
        self.setState({ CustomValidatorMsg: currentCustomValidatorMsg });
        return false;
      } else {
        currentCustomValidatorMsg['media'][index] = 'The media field is required.';
        self.setState({ CustomValidatorMsg: currentCustomValidatorMsg });
        return false;
      }
    } else {
      currentCustomValidatorMsg['media'][index] = '';
      self.setState({ CustomValidatorMsg: currentCustomValidatorMsg });
      return true;
    }
  }

  // validateSelectedCategories(category) {
  //   let self = this;
  //   let currentCustomValidatorMsg = self.state.customValidatorMsg;
  //   if (_.isEmpty(category)) {
  //     currentCustomValidatorMsg['categoryIds'] = 'Please select atleast one category.';
  //     self.setState({ CustomValidatorMsg: currentCustomValidatorMsg });
  //     return false;
  //   } else {
  //     currentCustomValidatorMsg['categoryIds'] = '';
  //     self.setState({ CustomValidatorMsg: currentCustomValidatorMsg });
  //     return true;
  //   }
  // }


  handleSubmit(e) {
    let self = this
    let media = [];
    let imgArr = []
    for (let i = 0; i < self.cateLayoutMap.length; i++) {
      imgArr.push(self.validateImageType('thumb_' + self.cateLayoutMap[i]['layoutType'], self.state['thumb_' + self.cateLayoutMap[i]['layoutType']]));
    }

    imgArr.push(self.validateImageType('fullImage', self.state['fullImage']));

    _.each(self.state.systemLanguages, function (value, index) {
      if (self.state.languageArr[index]) {
        media.push(self.validateMediaType(self.state.contentType, (self.state.systemMedia && self.state.systemMedia[index] && self.state.systemMedia[index]['file']) ? self.state.systemMedia[index]['file'] : "", index));
      }
    })



    let categoryvalidator = self.validateSelectedCategories(self.cateLayoutMap);
    let { customValidatorMsg } = self.state;
    if (self.state.contentType === 'IMAGE') {
      self.validator.fields.title = true;
    }
    if (self.validator.allValid() && media.indexOf(false) === -1 && imgArr.indexOf(false) === -1 && categoryvalidator === true && !customValidatorMsg['fullImage'] && !customValidatorMsg['thumb_GRID-RT'] && !customValidatorMsg['thumb_GRID-SQ']) {


      self.setState({ showErrorMsg: false });


      let files = [];

      if (this.state.fullImage) {
        files.push({ 'key': 'fullImage', 'folder': 'fullImage', file: this.state.fullImage });
      }


      if (this.state['thumb_GRID-RT']) {
        files.push({ 'key': 'thumb_GRID-RT', 'folder': 'thumb', file: this.state['thumb_GRID-RT'] });
      }


      if (this.state['thumb_GRID-SQ']) {
        files.push({ 'key': 'thumb_GRID-SQ', 'folder': 'thumb', file: this.state['thumb_GRID-SQ'] });
      }




      if (this.state.systemMedia && this.state.systemMedia.length) {
        let filePath = '';
        if (process.env.REACT_APP_APP_ENV == 'prod') {
          filePath = [Constants.VIDEO, Constants.AUDIO].indexOf(this.state.contentType) !== -1 ? 'media/core-contents' : 'media';
        } else {
          filePath = this.state.contentType == Constants.VIDEO ? 'media/original-video' : 'media';
        }

        _.each(this.state.systemMedia, function (value, index) {
          if (value && value['languageSlug']) {
            files.push({ 'key': 'media/' + value['languageSlug'], 'folder': filePath, file: value['file'], vimeoKey: 'vimeo_' + value['languageSlug'] });
          }
        })
      }



      this.uploadFilesToServers(files).then((uploadedFileRes) => {

        let stateData = this.state;
        let mediaFilePath = uploadedFileRes['media'] ? uploadedFileRes['media'] : stateData.mediaUrl;

        let languageData = [];
        _.each(this.state.systemLanguages, function (value, index) {
          // checking that s3 return having a new value
          // value['contentObj']['media'] = (!_.isEmpty(uploadedFileRes['media/' + value['slug']])) ? uploadedFileRes['media/' + value['slug']] : (value['contentObj']['mediaUrl']) ? value['contentObj']['mediaUrl'] : "";
          if (stateData.languageArr[index]) {
            value['contentObj']['vimeoId'] = uploadedFileRes && uploadedFileRes['vimeo_' + value['slug']] ? uploadedFileRes['vimeo_' + value['slug']] : null;
            languageData.push(value['contentObj']);
          }
        })


        let ageGroupId = [];


        if (!self.state.contentId) {
          ageGroupId = stateData.ageGroups.filter((item) => {
            if (item.title === 'All Ages') {
              return item;
            }

          }).map((item) => {
            return item._id;
          })
        }

        let requestParam = {
          "contentId": stateData.contentId,
          "categoryId": stateData.categoryId,
          "titleColor": stateData.titleColor,
          "titlePosition": stateData.titlePosition ? stateData.titlePosition : Constants.MIDDLE,
          "thumbTitleColor": stateData.thumbTitleColor,
          "dialColor": stateData.dialColor,
          "contentType": stateData.contentType,
          "status": 'PENDING',
          "tags": stateData.tags,
          "layoutType": stateData.layoutType,
          "order": stateData.order ? stateData.order.toString() : '1',
          "fullImage": uploadedFileRes['fullImage'] ? uploadedFileRes['fullImage'] : stateData.image,
          "contentLang": languageData,
          "ageGroupId": ageGroupId
        }



        if (Object.keys(uploadedFileRes).length > 0) {
          self.setState({ s3UploadedFiles: uploadedFileRes })
        }

        if (requestParam['fullImage'] && typeof requestParam['fullImage'] === 'object') {
          requestParam['fullImage'] = ''
        }

        self.appendCatLayoutImage(self.cateLayoutMap, uploadedFileRes, function (cateLayoutMap) {
          requestParam['cateLayoutMap'] = cateLayoutMap;
          if (self.state.contentId) {
            requestParam.status = stateData.status;
            self.props._updateContent(requestParam);
          } else {
            self.props._createContent(requestParam);
          }
        })

      }, (error) => {
        console.log('error', error);
      })


    } else {
      self.validator.showMessages();
      self.forceUpdate();
    }
    e.preventDefault();
  }

  LanguagesToRender() {
    let self = this;
    let languages = [];
    _.each(this.state.systemLanguages, function (value, index) {


      if (value.slug === Constants.ENGLISH) {
        languages.push(

          <FormGroup check inline>
            <Label check>
              <Input type="checkbox" disabled={(value.slug === Constants.ENGLISH) ? true : false} checked={true} />
              {value.title}
            </Label>
          </FormGroup>
        );
      } else {
        languages.push(
          <FormGroup check inline>
            <Label check>
              <Input type="checkbox" checked={self.state.languageArr[index]} onChange={(event) => self.showHideLanguageSection(event, index)} value={self.state.languageArr[index]} />
              {value.title}
            </Label>
          </FormGroup>
        );
      }
    })
    return (<div className="select-language"><Label>Choose Language: </Label> {languages}</div>)
  }


  showHideLanguageSection(event, index) {
    this.state.languageArr[index] = event.target.checked
    this.validator = new SimpleReactValidator();
    this.setState({ languageArr: this.state.languageArr })
  }

  updateTags(tags) {
    this.setState({ contentTags: tags })
  }

  render() {
    let { visible, closeModal, formData, categoryList } = this.props;
    let { categoryId, contentTags } = this.state;

    if (typeof categoryId === 'string') {
      categoryId = [];
    }
    this.validator.purgeFields();
    return (
      <Modal isOpen={visible} size="lg">
        <ModalHeader toggle={() => { closeModal(false) }}>{formData._id === 0 ? 'Add' : 'Update'} Content</ModalHeader>
        <Form onSubmit={(event) => { this.handleSubmit(event) }}>
          <ModalBody>
            {/* <Row>
              <Col md="12">
                {this.LanguagesToRender()}
              </Col>
            </Row>*/}
            <Row>
              <Col md="4">
                <Row>
                  <Col>
                    <Label htmlFor="categoryId">Select Categories</Label>
                    <Input type="select" name="categoryId[]" id="categoryId" onChange={(event) => { this.onSelectCategory(event) }} multiple>
                      {this.createSelectOption(categoryList.result)}
                    </Input>
                    <div className="validation-error">{this.state.customValidatorMsg.categoryIds && <div className="srv-validation-message">{this.state.customValidatorMsg.categoryIds}</div>}</div>

                  </Col>
                </Row>
                <Row>
                  <Col>
                    <FormGroup>
                      <Label htmlFor="contentType">Content Type</Label>
                      <Input type="select" name="contentType" id="contentType" value={this.state.contentType} onChange={(event) => { this.handleChange(event) }}>
                        <option value="">Select Content Type</option>
                        {this.createContentTypeOption()}
                      </Input>
                      <div className="validation-error">{this.validator.message('contentType', this.state.contentType, 'required')}</div>
                    </FormGroup>
                  </Col>
                </Row>

                {(this.state.contentType === 'AUDIO') &&
                  <Row>
                    <Col>
                      <FormGroup tag="fieldset">
                        <Label>Player Type</Label>
                        <FormGroup check>
                          <Label check htmlFor="layoutTypeMusic" className="pr-5">
                            <Input type="radio" name="layoutType" id="layoutTypeMusic" checked={this.state.layoutType === Constants.MUSIC} value={Constants.MUSIC} onChange={(event) => { this.handleChange(event) }} />{' Music'}
                          </Label>
                          <Label check htmlFor="layoutTypeMeditation">
                            <Input type="radio" name="layoutType" id="layoutTypeMeditation" checked={this.state.layoutType === Constants.MEDITATION} value={Constants.MEDITATION} onChange={(event) => { this.handleChange(event) }} />{' Meditation'}
                          </Label>
                        </FormGroup>
                        <div className="validation-error">{this.validator.message('layoutType', this.state.layoutType, 'required')}</div>
                      </FormGroup>
                    </Col>
                  </Row>
                }
                <Row className="no-gutters">

                  <Col>
                    <FormGroup>
                      <Label htmlFor="thumbTitleColor">Thumb Title Color</Label>
                      <Input type="color" name="thumbTitleColor" id="thumbTitleColor" value={this.state.thumbTitleColor} onChange={($event) => { this.handleChange($event) }}></Input>

                    </FormGroup>
                  </Col>
                  {(this.state.contentType === 'AUDIO' || this.state.contentType === 'EPUB') ?
                    <Fragment>
                      <Col className="col-narrow">
                        <FormGroup>
                          <Label htmlFor="titleColor">Title Color</Label>
                          <Input type="color" name="titleColor" id="titleColor" value={this.state.titleColor} onChange={(event) => { this.handleChange(event) }}></Input>

                        </FormGroup>
                      </Col>
                      <Col>
                        <FormGroup>
                          <Label htmlFor="dialColor">Dial Color</Label>
                          <Input type="color" name="dialColor" id="dialColor" value={this.state.dialColor} onChange={(event) => { this.handleChange(event) }}></Input>

                        </FormGroup>
                      </Col>
                    </Fragment>
                    : null}
                </Row>
                <Row>
                  <Col>
                    <FormGroup>
                      <Label htmlFor="dialColor">Content Tags</Label>
                      <Tags tags={contentTags} updateTags={(tags) => { this.updateTags(tags) }}></Tags>
                    </FormGroup>
                  </Col>
                </Row>
              </Col>

              <Col md="4" className="col-lr-bdr">
                {this.SectionsToRender()}
              </Col>
              <Col md="4">
                <Row>
                  <Col xs="12">
                    <FormGroup>
                      <Label htmlFor="status">Content Priority</Label>
                      <Input type="select" name="order" id="order" value={this.state.order} onChange={($event) => { this.handleChange($event) }}>
                        <option value={1}>{this.formatTitlePosLabel(Constants.HIGH)}</option>
                        <option value={2}>{this.formatTitlePosLabel(Constants.MEDIUM)}</option>
                        <option value={3}>{this.formatTitlePosLabel(Constants.LOW)}</option>
                      </Input>
                    </FormGroup>
                  </Col>
                </Row>


                <Row>
                  <Col xs="12">
                    <FormGroup>
                      <Label htmlFor="status">Paid or Free!</Label>
                      <Input type="select" name="tags" id="tags" value={this.state.tags} defaultValue={this.state.tags} onChange={($event) => { this.handleChange($event) }}>
                        <option value="FREE">Available for free</option>
                        <option value="PAID">Only available on subscription</option>
                      </Input>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col xs="12">
                    <FormGroup>
                      <Label htmlFor="status">Content Title Position</Label>
                      <Input type="select" name="titlePosition" id="titlePosition" value={this.state.titlePosition} onChange={($event) => { this.handleChange($event) }}>
                        <option value={Constants.MIDDLE}>{this.formatTitlePosLabel(Constants.MIDDLE)}</option>
                        <option value={Constants.TOP}>{this.formatTitlePosLabel(Constants.TOP)}</option>
                        <option value={Constants.BOTTOM}>{this.formatTitlePosLabel(Constants.BOTTOM)}</option>
                        <option value={Constants.BELOW_THUMB}>{this.formatTitlePosLabel(Constants.BELOW_THUMB)}</option>
                      </Input>
                    </FormGroup>
                  </Col>
                </Row>
                {/*
                <Row>
                  <Col xs="12">
                      <FormGroup>
                          <Label htmlFor="status">Age Group</Label>
                          {this.state.ageGroups.length > 0 && 
                          <div className="react-multi-select">
                            <ReactMultiSelectCheckboxes                              
                              placeholderButtonLabel={"Select age group"}
                              hideSearch={true} 
                              options={this.createAgeGroupOption()}
                              value={this.state.selectedAgeGroup}
                              onChange={(e)=>{this.handleMultiSelectChange(e)}}
                              optionClassName={'test'}                            
                            />
                            </div>
                          }
                          <div className="validation-error">{ this.validator.message('ageGroup', this.state.selectedAgeGroup, 'required') }</div>
                      </FormGroup>
                  </Col>
                </Row>
                */}
              </Col>
            </Row>
            <hr />
            <Row>
              {this.filesSection()}
            </Row>


            {(this.state.contentType === 'AUDIO' || this.state.contentType === 'EPUB' || this.state.contentType === 'VIDEO') ?


              <Row>
                {this.MediaToRender()}
              </Row>



              : null}




            {(this.state.showErrorMsg) ? <Alert color="danger">{this.state.errorMsg}</Alert> : null}
          </ModalBody>
          <ModalFooter className="justify-content-left">
            {formData.status !== 'PUBLISHED' &&
              <Button type="submit" size="sm" color="primary">{formData._id === 0 ? 'Save' : 'Update'}</Button>
            }{' '}
            <Button type="reset" size="sm" color="danger" onClick={() => { closeModal() }}>Cancel</Button>

          </ModalFooter>
        </Form>
      </Modal>
    );
  }
}


const mapStateToProps = (state) => {
  let stateData = {
    requestProcess: state.authorContent.requestProcess
  };

  if (state.authorContent.createContent) {
    stateData['createContent'] = state.authorContent.createContent;
  }


  if (state.authorContent.updateContent) {
    stateData['updateContent'] = state.authorContent.updateContent;
  }

  if (state.category && state.category.data) {
    stateData['categoryList'] = state.category.data;
  }

  if (state.pillar && state.pillar.data) {
    stateData['pillarList'] = state.pillar.data.result;
  }
  if (state.authorContent && state.authorContent.contentDetails) {
    stateData['contentDetails'] = state.authorContent.contentDetails;
  }

  return stateData;
};

const mapDispatchToProps = (dispatch) => {
  return {
    _createContent: (params, progressCallBack) => {
      dispatch(createContent(params, progressCallBack));
    },

    _updateContent: (params, progressCallBack) => {
      dispatch(updateContent(params, progressCallBack));
    },

    _getPillarCategory: (params) => {
      dispatch(getPillarCategory(params));
    },

    _getPillars: (params) => {
      dispatch(getPillars(params));
    },

    _getContentDetails: (params) => {
      dispatch(getContentDetails(params));
    },

  }
};


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddEditContent);
