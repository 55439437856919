import React, { Component } from 'react';
import { FormatTitlePosLabel } from '../../utils/common';

import {  Table, Modal, ModalHeader, ModalFooter, ModalBody, Button, FormGroup, Input, Alert } from "reactstrap";

import moment from 'moment';


import * as _ from 'underscore';



class ShowAllAffliatedUsers extends Component {

  constructor(props) {
    super(props);
    let userList = (props.userList && props.userList.list)  ? props.userList.list : [];
    if(userList && userList.length > 0){
      userList.sort(function(a, b) {
          return new Date(b.createdDate).getTime() - new Date(a.createdDate).getTime();
      });
    }
    this.state = {
      selectedRow : props.selectedRow,
      userList : userList,
      totalRecords : (props.userList && props.userList.totalRecords) ? props.userList.totalRecords : 0,
      activeTab : props.activeTab,
      totalEarnings : 0,
      totalCommissionToPay : 0,
      totalAmountPaid : (props.userList && props.userList.totalAmountPaid) ? parseFloat(props.userList.totalAmountPaid) : 0,
      showPaymentUpdateSection : false,
      paidAmount : null,
      remarks : "",
    }


    this.userListHeadings = [
      { "displayName": "Email", sort : false},
      { "displayName": "Current Subscription", sort : false},
      { "displayName": "Subscribed On", sort : false},
      { "displayName": "Payment taken", sort : false},
    ];

  }

  componentDidMount() {
    this.setPaymentData();
  }


  setPaymentData() {
    let totalEarnings = 0; 
    let totalCommissionToPay = 0; 
    _.each(this.state.userList, function(value, index) {
      totalEarnings = totalEarnings + parseFloat(value['paymentHistory']['amount'])
    });
    totalCommissionToPay = ( parseFloat(totalEarnings) * parseInt(this.state.selectedRow.commission) / 100 )
    this.setState({totalCommissionToPay : totalCommissionToPay, totalEarnings : totalEarnings})
  }


  setGridHeaders(headingData) {
    let gridHeadings = [];
    gridHeadings.push(<th key={headingData[0]['displayName']}>#</th>)
    _.each(headingData, function(value, index){
      {
        gridHeadings.push(<th key={index} className={value['displayName'] === 'Payment taken' && 'text-right'}>{value['displayName']}</th>)
      };

    })

    return (<tr className="table-head">{gridHeadings}</tr>)
  }


  setGridData(data) {
    let gridData = [];
    _.each(data, function(value, index){
      {
        gridData.push(
          <tr key={value['_id']}>
            <th scope="row">{index + 1}</th>
            <td>{value['user']['email']}</td>
            <td>{FormatTitlePosLabel(value['notifyType'])}</td>
            <td>{moment(value['createdDate']).format("DD-MMM-YYYY")}</td>
            <td class="text-right">${(value['paymentHistory']) ? value['paymentHistory']['amount'] : 0}</td>
          </tr>
       )
      };
    })
    return gridData; 
  }


  updatePayment() {
    let params = {
      discountId : this.state.selectedRow._id,
      paidAmount : this.state.paidAmount,
      remarks : this.state.remarks
    }
    this.props.addCommissionPayment(params)
  }

  tooglePaymentUpdateSection() {
    this.setState({showPaymentUpdateSection : !this.state.showPaymentUpdateSection});
  }


  handleChange(event) {
    let target = event.target;
    let value = event.target.value;

    if (target.name === 'paidAmount') {
      value = (value && value < 0) ? 0 : value
    }

    const name = target.name;
    this.setState({[name]: value});
  }

  CommisionLayout() {
    let { showPaymentUpdateSection, paidAmount, remarks } = this.state;
    return (
   
      <tr >
        <td className="text-right"  style={{borderWidth:'3px'}} >
          <p ><strong>Total Payment received: <span className="minW-160 inline-block">${this.state.totalEarnings}</span></strong></p>
       
          <div className="commission-table">
           
            <table className="table">
              <tbody>
                <tr>
                  <td >                  
                  Total Commission Amount({this.state.selectedRow.commission}%): <span className=" inline-block float-right">${this.state.totalCommissionToPay}</span>
                  </td>
                </tr>
                <tr>
                  <td style={{border:'none'}}>                  
                  Total Commission Paid: <span className=" inline-block float-right">${this.state.totalAmountPaid}</span>
                  </td>
                </tr>
                <tr>
                  <td >                  
                  <strong>Remaining Commission Amount: <span className=" inline-block float-right">${(this.state.totalCommissionToPay - this.state.totalAmountPaid).toFixed(2)}</span></strong>
                  <div>
                    
                  </div>
                  { ((this.state.totalCommissionToPay - this.state.totalAmountPaid) > 0) ?
                    <div>
                      <span className="link" onClick={() => {this.tooglePaymentUpdateSection()}}>Click here</span> to update
                    </div> : null
                  }
                  </td>
                </tr>
              </tbody>
            </table>
            {(((this.state.totalCommissionToPay - this.state.totalAmountPaid) > 0) && showPaymentUpdateSection) ? <div className="withdraw-commission">
            <FormGroup>
              <Input type="number"  name="paidAmount" placeholder="Add paid amount" value={paidAmount}  pattern="^\S*$"  onChange={($event)=>{this.handleChange($event)}} />                                              
              <div className="validation-error"></div>   
            </FormGroup>
            <FormGroup>
              <Input type="textarea"  name="remarks" placeholder="Enter Note" value={remarks} onChange={($event)=>{this.handleChange($event)}}  />                                              
              <div className="validation-error"></div>   
            </FormGroup>
            </div> : null}
          </div>
        </td>
      </tr>
    )
  }



  render() {
    let {visible, closeModal, screenErrMsg} = this.props;
    let { userList, selectedRow, showPaymentUpdateSection, remarks, paidAmount } = this.state;

    return (
    
      <div>
        <Modal isOpen={visible} toggle={() => closeModal()} size="lg">
          <ModalHeader toggle={() => closeModal()}>User list for coupon {selectedRow.couponCode} </ModalHeader>
          <ModalBody>
            <div>  
              <Table hover>
                <thead>
                   {this.setGridHeaders(this.userListHeadings)}
                </thead>
                <tbody>
                  {
                    this.setGridData(userList)
                  }
                </tbody>
              </Table>
              
            </div>


            <div>

            <Table>              
              <tbody>
              {this.CommisionLayout()}  
              </tbody>              
              
            </Table>
            
            {(screenErrMsg) ? <Alert color="danger">
              {screenErrMsg}
            </Alert> : null}

              
            </div>



            
            
          </ModalBody>

          {<ModalFooter>
            <Button color="primary" disabled={ ((this.state.totalCommissionToPay - this.state.totalAmountPaid) <= 0) || !showPaymentUpdateSection || !paidAmount || !remarks  } onClick={() => {this.updatePayment()}}>Update Payment</Button>{' '}
            <Button color="danger" onClick={() => closeModal()}>Close</Button>{' '}
          </ModalFooter>}

        </Modal>
      </div>
      
    );
  }
}

export default ShowAllAffliatedUsers;
