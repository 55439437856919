import React, { Component } from 'react';
import { Loader } from '../../../utils/common';
import DataGrid from "g6reactdatagrid";
import { Row, Col, Nav, NavItem, NavLink, Alert, Modal, ModalHeader, ModalBody, ModalFooter, Button} from "reactstrap";
import { AppSwitch } from '@coreui/react'
import AddStaticLanguage from './AddStaticLanguage';
import UpdateStaticLanguage from './UpdateStaticLanguage';
import Constants from '../../../config/constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit } from '@fortawesome/free-solid-svg-icons'
import Session from '../../../utils/session';
import * as _ from 'underscore';
import CheckPasswordPopup from '../../../shared/checkPasswordPopup';



class StaticLanguage extends Component {


  constructor(props) {
    super(props);
    this.onCloseModal = this.onCloseModal.bind(this);
    this.state = {
      screenError : "",
      popupError : "",
      languageList : [],
      selectedRow : {},
      openAddStaticlanguageModal : false,
      openUpdateStaticlanguageModal : false,
      openCheckPasswordModal : false,
      openAlertModal : false,
      systemLanguages : Session.getSessionData('languages')['languages']
    }

    this.columns = [
      {"columnName": 'key',"displayName": 'Key',"sort": false},
      {"columnName": 'count',"displayName": 'Total Languages',"sort": false}
    ];

  }

  componentDidMount() {
    //let self = this;
    if (process.env.REACT_APP_CHECK_PASSWORD === 'ON') {
      this.setState({openCheckPasswordModal : true});
      return;
    }else{
      this.getStaticLanguages();
      this.setColumns();
      this.closeAlertModal();
    }  
  }

  isPasswordMatched(matched) {
    if (matched) {
      this.getStaticLanguages();
      this.setColumns();
      this.closeAlertModal();
    }
  }

  setColumns() {
    let self = this
    _.each(this.state.systemLanguages, function(value, index){
      self.columns.push({ 
        "columnName": value.slug,
        "displayName": value.title,
        "sort": false
      })
    })
  }


  getStaticLanguages() {
    Loader(true)
    this.props._getStaticLanguage({});
  }

  

  componentWillReceiveProps(nextProps) {
    let self = this;
    let languageList = [];
    // Loader(nextProps.requestProcess);

    if (nextProps.languageList) {
      if (nextProps.languageList.statusCode === 200) {
        let listData = (nextProps.languageList.result.languageList.length) ? nextProps.languageList.result.languageList   : [];
        listData.map(function (element, key) {
          element['key'] = element['_id']
          if (element['data'].length) {
            _.each(element['data'], function(value, index){
              element['count'] = element['data'].length
              element[value['languageSlug']] = value['value']
              element['status'] = value['status']
            })
            languageList.push(element);
          }
          return element;
        });
        Loader(false);
        self.setState({languageList: languageList });
      }else{
        self.setState({screenError : Constants.SOMETHING_WRONG})
      }

    }


    if (nextProps.languageAdded) {
       if (nextProps.languageAdded.statusCode && nextProps.languageAdded.statusCode === 200) {
         self.getStaticLanguages();
         self.onCloseModal();
       }else {
         this.setState({popupError : (nextProps.languageAdded['data'] && nextProps.languageAdded['data']['statusDescription']) ? nextProps.languageAdded['data']['statusDescription'] : Constants.SOMETHING_WRONG})
       }
    }



    if (nextProps.languageUpdated) {
       if (nextProps.languageUpdated.statusCode && nextProps.languageUpdated.statusCode === 200) {
         self.getStaticLanguages();
         self.onCloseModal();
       }else {
         this.setState({popupError : (nextProps.languageUpdated['data'] && nextProps.languageUpdated['data']['statusDescription']) ? nextProps.languageUpdated['data']['statusDescription'] : Constants.SOMETHING_WRONG})
       }
    }


    if (nextProps.statusChanged) {
       if (nextProps.statusChanged.statusCode && nextProps.statusChanged.statusCode === 200) {
         self.getStaticLanguages();
         self.onCloseModal();
       }else {
         this.setState({screenError : (nextProps.languageAdded['data'] && nextProps.languageAdded['data']['statusDescription']) ? nextProps.languageAdded['data']['statusDescription'] : Constants.SOMETHING_WRONG})
       }
    }
  }

  
  closeAlertModal() {
    this.setState({selectedRow : {}, openAddStaticlanguageModal : false, openUpdateStaticlanguageModal : false, openAlertModal : false, openCheckPasswordModal : false})
  }
  
  onCloseModal() {
    this.setState({selectedRow : {}, openAddStaticlanguageModal : false, openUpdateStaticlanguageModal : false, openAlertModal : false, openCheckPasswordModal : false})
    this.getStaticLanguages();
  };

  addStaticLanguageModal() {
    let self = this;
    _.each(self.state.systemLanguages, function(value, index){

      self.state.systemLanguages[index]['staticLangObj'] = {
        value: "",
        languageSlug : value['slug'],
        status : true
      }
    })

    this.setState({openAddStaticlanguageModal: true, selectedRow: {} , systemLanguages : self.state.systemLanguages});
  }

  triggerNotifiicationAlert() {
    this.setState({
      openAlertModal : true
    })
  }



  updateStaticLanguageModal(rowData) {
    let self = this;
    _.each(self.state.systemLanguages, function(value, index){
      let langObj = _.find(rowData['data'], function(dataObj){ return dataObj['languageSlug'] === value['slug']; });

      self.state.systemLanguages[index]['staticLangObj'] = (langObj && !_.isEmpty(langObj) ? langObj : {value: "",languageSlug : value['slug'],        status : true})
    })

    this.setState({openUpdateStaticlanguageModal: true, selectedRow: rowData , systemLanguages : self.state.systemLanguages});
  }

  addStaticLanguage(event, obj) {
    this.props._addStaticLanguage(obj);
  }


  updateStaticLanguage(event, obj) {
    this.props._updateStaticLanguage(obj);
  }

  changeStatus(rowData) {
    let params = {
      key : rowData.key,
      status : !rowData.status,
    }
    this.props._changeStaticLanguageStatus(params);
  }

  triggerNotifiication() {
    this.props._triggerNotificaton({});
    this.closeAlertModal();
  }

  

  render() {


    let self = this;
    let { systemLanguages, screenError, languageList, openAddStaticlanguageModal, openUpdateStaticlanguageModal, selectedRow, popupError, openAlertModal, openCheckPasswordModal } = this.state;
    let totalRecordsLabel = '##TOTAL_RECORDS## '+(languageList.length > 1 ? 'Records' : 'Record')
    let options = {
      totalRecords: languageList.length,
      totalRecordsLabel: totalRecordsLabel,
      rowsPerPage: 1000,
      page: 0,
      loadMore: false,
      customColumn: {
        isActive: true,
        columnTitle: '',
        columnContent: (rowData) => {
          return (
            <div>
              <Nav>

                <NavItem>
                  <NavLink href="#" onClick={() => { self.updateStaticLanguageModal(rowData); }}><FontAwesomeIcon icon={faEdit} size='1x' /></NavLink>
                </NavItem>

                 <NavItem>
                    <AppSwitch className={'mx-1'} variant={'pill'} color={'primary'} label checked={ rowData.status } onChange={() =>
                    {self.changeStatus(rowData)}} />
                 </NavItem>
              </Nav>
            </div>
          );
        }
      },

      onPageChange(pageNumber) {
        self.state.page = pageNumber;
        self.state.offset = parseInt(pageNumber) * parseInt(self.state.rowsPerPage)

      },
      onColumnSortChange: (column, direction) => {
        direction = (direction === "ASC") ? -1 : 1;
        // self.setState({sortColumn: column, sortValue: direction}, ()=>{self.getPillarCategoryList();})
      }
    };

    return (
      <div className="content">
        <Row className="pb-4">
          <Col lg={12} sm={12}>
            <h3>Device Static Languages</h3>
          </Col>
        </Row>

        <Row className="pb-4">
          <Col lg={12} sm={12} className="text-right">
            {
               <div>
                 <button className="btn btn-primary" color="link" onClick={() => self.addStaticLanguageModal()}>Add Static Language</button>
                 &nbsp;
                 <button className="btn btn-warning" color="link" onClick={() => self.triggerNotifiicationAlert()}>Triggger Notification</button>
               </div>
            }
          </Col>
        </Row>



        {screenError && <Alert color="danger">
          {screenError}
        </Alert>}

        <Row>
          <Col lg={12} sm={12}>


            {openAddStaticlanguageModal && <AddStaticLanguage visible={openAddStaticlanguageModal}  closeModal={this.onCloseModal} selectedRow={selectedRow} systemLanguages={systemLanguages} popupError={popupError} handleSubmit={(event, newlangData) => this.addStaticLanguage(event, newlangData)} />}



             {openUpdateStaticlanguageModal && <UpdateStaticLanguage visible={openUpdateStaticlanguageModal}  closeModal={this.onCloseModal} selectedRow={selectedRow} systemLanguages={systemLanguages} popupError={popupError} handleSubmit={(event, newlangData) => this.updateStaticLanguage(event, newlangData)} />}
            


            <div className="dataTable-wrapper pillarList_grid">
              <DataGrid
                columns={this.columns}
                options={options}
                rows={languageList}
              />
            </div>

          <Modal isOpen={ openAlertModal }>
            <ModalHeader toggle={()=>{this.closeAlertModal()}}>Trigger Notification</ModalHeader>
            <ModalBody>
              Are you sure you want to you want to send notificaton to all users 
            </ModalBody>
            <ModalFooter className="justify-content-left">
              <Button type="button" size="sm" color="primary" onClick={()=>{this.triggerNotifiication()}}>Send</Button>{' '}
              <Button type="reset" size="sm" color="danger"  onClick={() => { this.closeAlertModal() }}>Cancel</Button>
            </ModalFooter>
          </Modal>

          { openCheckPasswordModal && <CheckPasswordPopup openCheckPasswordModal={openCheckPasswordModal}  closeModal={(resp) => {this.closeModal(resp)}} isPasswordMatched={(resp) => this.isPasswordMatched(resp)}  />} 
            <Row className="pb-4">
              <Col lg={8} sm={12}>
                <h3>Settings</h3>                 
                {/*<Col lg={4} sm={12} className="text-right">
                 {
                    //<button className="btn btn-primary" color="link" onClick={() => this.addAppSettingModal()}>Add New Setting</button>
                 }
               </Col>*/}
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    );
  }
}

export default StaticLanguage;
