import React, { Component } from 'react';
import store from './store';
import { Router, Route, browserHistory } from 'react-router';
import { syncHistoryWithStore } from 'react-router-redux';
import Login from './pages/auth';
import Parent from './pages/parent';
import Pillar from './pages/pillar';
import Category from './pages/category';
import Content from './pages/content';
import Feedback from './pages/feedback';
import Versions from './pages/versions';
import Settings from './pages/settings';
import Discount from './pages/discount';
import Trophy from './pages/trophy';
import PaymentHistory from './pages/paymentHistory';
import DefaultLayout from './container/DefaultLayout'
import Message from './components/Message'
import Loader from './components/Loader'
import Session from './utils/session'

import { setHttpClientConfig } from './utils/common';
import httpClient from './services/http';
import crypt from './services/crypt';
import Notifications from './pages/notifications';
//import EmailNotification from './pages/emailNotification';
import SystemEmail from './pages/systemEmail';
import whatsAppCampaign from './pages/whatsAppCampaign';
import UserNotification from './pages/userNotification';
import AdminUsers from './pages/adminUsers';
import WhatsNew from './pages/whatsNew';


import BackgroundMusic from './pages/backgroundMusic';
import DailyQuest from './pages/dailyQuest';
import StaticLanguage from './pages/languageManagement/StaticLanguage';

// import SocialProfiles from './marketing/socialProfiles';
import SocialInsights from './marketing/socialInsights';
import authorContent from './pages/authorContent';
import printable from './pages/printable';

import EmailCampaign from './pages/emailCampaign';
import Vimeo from './pages/vimeo';
import Playlist from './pages/playlist/Playlist';
import TrendingContent from './pages/trendingContent/TrendingContent';
import CustomModule from './pages/customModule/CustomModule';

import SocialProfiles from './pages/marketing/socialProfiles';
import DeepLink from './pages/marketing/DeepLink';


import './App.scss';
import './scss/style.scss'


class AppRoute extends Component {

  constructor(props) {
    super(props);
    this.state = {
      pageLoading: false
    }
    this.setConfiguration()
  }


  setConfiguration() {
    let _self = this;
    crypt.setSaltKey(process.env.DATA_ENCRYPT_KEY);
    setHttpClientConfig().then(function () {
      httpClient.getAppConfig();
      httpClient.getAppLanguages();
      _self.setState({ pageLoading: true });
    });
  }

  routeChange(previousRoute, nextRoute) {
    let sessionData = Session.getSessionData();
    if (!sessionData || !sessionData['jwtToken']) {
      browserHistory.push("login")
    }
  }


  render() {
    const history = syncHistoryWithStore(browserHistory, store);
    let { pageLoading } = this.state;
    return (
      <div>
        <Message />
        <Loader />
        {pageLoading ?
          <Router history={history} >
            <Route path="/" component={DefaultLayout} onChange={this.routeChange} onEnter={this.routeChange}>

              <Route exact path="parent" component={Parent}></Route>
              <Route exact path="pillar" component={Pillar}></Route>
              <Route exact path="pillar-categories" component={Category}></Route>
              <Route exact path="pillar-content" component={Content}></Route>
              {/* <Route exact path="trending-content" component={TrendingContent}></Route> */}
              <Route exact path="custom-module" component={CustomModule}></Route>
              <Route exact path="custom-content" component={TrendingContent}></Route>
              <Route exact path="notifications" component={Notifications}></Route>

              <Route exact path="users-feedback" component={Feedback}></Route>
              <Route exact path="system-email" component={SystemEmail}></Route>
              <Route exact path="background-music" component={BackgroundMusic}></Route>
              <Route exact path="daily-quest" component={DailyQuest}></Route>

              <Route exact path="app-versions" component={Versions}></Route>
              <Route exact path="app-static-languages" component={StaticLanguage}></Route>
              <Route exact path="settings" component={Settings}></Route>
              <Route exact path="email-campaign" component={EmailCampaign}></Route>
              <Route exact path="whatsapp-campaign" component={whatsAppCampaign}></Route>
              <Route exact path="user-notification" component={UserNotification}></Route>
              <Route exact path="discount" component={Discount}></Route>
              <Route exact path="trophy" component={Trophy}></Route>
              <Route exact path="payment-history" component={PaymentHistory}></Route>

              <Route exact path="admin-users" component={AdminUsers}></Route>
              <Route exact path="whats-new" component={WhatsNew}></Route>
              <Route exact path="free-printable" component={printable}></Route>


              {/* <Route exact path="marketing-social" component={SocialProfiles}></Route> */}
              <Route exact path="social-:socialType-insights" component={SocialInsights}></Route>
              <Route exact path="author-content" component={authorContent}></Route>
              <Route exact path="vimeo" component={Vimeo}></Route>
              <Route exact path="playlist" component={Playlist}></Route>

              <Route exact path="marketing-social" component={SocialProfiles}></Route>
              <Route exact path="deep-link" component={DeepLink}></Route>

            </Route>

            {/*KEEPING LOGIN OUT OF LAYOUT*/}
            <Route path="login" exact={true} component={Login} />
            <Route path='*' exact={true} component={Login} />
          </Router>
          :
          null
        }
      </div>
    );
  }
}

export default AppRoute;
