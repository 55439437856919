import React, { Component } from 'react';
import { Loader } from '../../utils/common';
import { Row, Col, Modal, Button, ModalHeader, ModalBody, ModalFooter, Nav, NavItem, NavLink, TabContent, TabPane, Alert, Table, Pagination, PaginationItem, PaginationLink } from "reactstrap";

import classnames from 'classnames';

import moment from 'moment';
import Constants from '../../config/constants';

import AddPromotionalDiscount from './addPromotionalDiscount';
import AddFreeDiscount from './addFreeDiscount';
import AddAffiliatedDiscount from './addAffiliatedDiscount';
import AddUserSpecificDiscount from './addUserSpecificDiscount';
import AddSocialDiscount from './addSocialDiscount';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashAlt, faCopy, faCheck, faTimes } from '@fortawesome/free-solid-svg-icons'


import ShowAllPromoUsers from './showAllPromoUsers'
import ShowAllAffliatedUsers from './showAllAffliatedUsers'

import ShowAllSocialUsers from './showAllSocialUsers'

import * as _ from 'underscore';



class Discount extends Component {

  constructor(props) {
    super(props);
    this.closeModal = this.closeModal.bind(this)
    this.addDiscount = this.addDiscount.bind(this)
    this.state = {
      discounts: [],
      openAddPromotionalDiscountModal: false,
      openDeleteDiscountModal: false,

      openAddAffiliatedDiscountModal: false,
      addAffiliatedDiscountPopupError: "",


      selectedRow: {},
      userList: {},
      selectedParent: {},
      addPromotionalDiscountPopupError: "",
      updatediscountPopupError: "",
      activeTab: Constants.PROMOTIONAL,
      openAddFreeDiscountModal: false,
      openAddSocialDiscountModal: false,
      addFreeDiscountPopupError: "",
      addSocialDiscountPopupError: "",
      userSpecificDiscountPopupError: "",
      appstoreDifferences: [],
      changeSubscriptionModal: false,
      screenErrMsg: "",
      isError: false,
      showPromoUsers: false,
      showSocialUsers: false,
      showAffliatedUsers: false,
      copyText: "Copy to clipboard",
      openUserSpecificDiscountModal: false
    }


    this.discountsPromotionalColumns = [
      { "displayName": "Promotion code", sort: false },
      { "displayName": "Discount", sort: false },
      { "displayName": "Description", sort: false },
      { "displayName": "Start On", sort: false },
      { "displayName": "Expired On", sort: false },
      { "displayName": "For User", sort: false },
      { "displayName": "Total User applied", sort: false },
      { "displayName": "Delete", sort: false },
    ];


    this.discountsAffliatedColumns = [
      { "displayName": "Promotion code" },
      { "displayName": "Discount", sort: false },
      { "displayName": "Commission", sort: false },
      { "displayName": "Description", sort: false },
      { "displayName": "Start On", sort: false },
      { "displayName": "Expired On", sort: false },
      { "displayName": "Affiliated To", sort: false },
      { "displayName": "Total User applied", sort: false },
      { "displayName": "Action", sort: false },
      // { "columnName": "type", "displayName": "Type", sort : false},
    ];


    this.freeDiscountsColumns = [
      { "displayName": "Promotion code", sort: false },
      { "displayName": "Discount", sort: false },
      { "displayName": "Description", sort: false },
      { "displayName": "Start On", sort: false },
      { "displayName": "Expired On", sort: false },
      { "displayName": "Used By", sort: false },
      { "displayName": "User Subscription", sort: false },
      { "displayName": "Delete", sort: false },
      // { "columnName": "type", "displayName": "Type", sort : false},  
    ];


    this.socialDiscountsColumns = [
      { "displayName": "Invite Url", sort: false },
      { "displayName": "Promotion code", sort: false },
      { "displayName": "Affiliated To", sort: false },
      { "displayName": "Total users", sort: false },
      { "displayName": "Actions", sort: false },
      // { "columnName": "type", "displayName": "Type", sort : false},  
    ];



    this.userSpecificDiscountsColumns = [
      { "displayName": "Email", sort: false },
      { "displayName": "Discount", sort: false },
      { "displayName": "Description", sort: false },
      { "displayName": "Is Used", sort: false },
      { "displayName": "Actions", sort: false },
    ];
  }

  componentDidMount() {
    this.getAlldiscounts(this.state.activeTab);
    this.getAlldifferences();
  }

  getAlldifferences() {
    this.props._getAlldifferences({})
  }


  componentWillReceiveProps(nextProps) {
    let self = this;
    let discounts = [];


    if ((nextProps.discountData && nextProps.discountData.statusCode === 200)) {
      let couponList = [];
      let totalRows = 0;
      if (nextProps.discountData && nextProps.discountData.result) {

        if (nextProps.discountData['result']['totalAmountPaid']) {
        }

        if (nextProps.discountData['result']['list'] && nextProps.discountData['result']['list']['length']) {
          nextProps.discountData['result']['list'].map((discount, key) => {

            nextProps.discountData['result']['list'][key]['email'] = (nextProps.discountData['result']['list'][key]['email']) ? nextProps.discountData['result']['list'][key]['email'] : "ALL";


            nextProps.discountData['result']['list'][key]['emailFree'] = (nextProps.discountData['result']['list'][key]['parents'] && nextProps.discountData['result']['list'][key]['parents']['email']) ? nextProps.discountData['result']['list'][key]['parents']['email'] : "Not Used";



            nextProps.discountData['result']['list'][key]['commissionPercentage'] = (nextProps.discountData['result']['list'][key] && nextProps.discountData['result']['list'][key]['commission'] && parseFloat(nextProps.discountData['result']['list'][key]['commission']) > 0) ? nextProps.discountData['result']['list'][key]['commission'] + "%" : "No Commission";


            nextProps.discountData['result']['list'][key]['startOn'] = (nextProps.discountData['result']['list'][key]['startOn']) ? moment(nextProps.discountData['result']['list'][key]['startOn']).format("DD-MMM-YYYY") : "--";


            nextProps.discountData['result']['list'][key]['expiredOn'] = (nextProps.discountData['result']['list'][key]['expiredOn']) ? moment(nextProps.discountData['result']['list'][key]['expiredOn']).format("DD-MMM-YYYY") : "--";


            nextProps.discountData['result']['list'][key]['affliatedEmail'] = (nextProps.discountData['result']['list'][key]['affliatedDiscounts'] && nextProps.discountData['result']['list'][key]['affliatedDiscounts']['email']) ? nextProps.discountData['result']['list'][key]['affliatedDiscounts']['email'] : "N/A";
          })
        }
        couponList = nextProps.discountData.result.list;
        totalRows = nextProps.discountData.result.rows;

      }

      self.setState({ discounts: couponList, totalRows });
    }


    if (nextProps.appstoreDifferences && nextProps.appstoreDifferences['result'] && nextProps.appstoreDifferences['result']['list']) {
      this.setState({ appstoreDifferences: nextProps.appstoreDifferences['result']['list'] })
    }

    if (nextProps.deleteData && nextProps.deleteData.statusCode === 200) {
      self.closeModal();
      self.getAlldiscounts(this.state.activeTab);
    }

    if (nextProps.addData) {
      if (nextProps.addData.statusCode === 200) {
        self.closeModal();
        self.getAlldiscounts(this.state.activeTab)
      } else {
        self.setState({ addPromotionalDiscountPopupError: (nextProps.addData && nextProps.addData.data && nextProps.addData.data.statusDescription) ? nextProps.addData.data.statusDescription : "" });
      }
    }


    if (nextProps.addUserDiscount) {
      if (nextProps.addUserDiscount.statusCode === 200) {
        self.closeModal();
        self.getAlldiscounts(this.state.activeTab)
        this.setState({ userSpecificDiscountPopupError: false })
      } else {
        self.setState({ userSpecificDiscountPopupError: (nextProps.addUserDiscount && nextProps.addUserDiscount.data && nextProps.addUserDiscount.data.statusDescription) ? nextProps.addUserDiscount.data.statusDescription : "" });
      }
    }


    if (nextProps.subscriptionChanged && nextProps.subscriptionChanged) {
      if (nextProps.subscriptionChanged.statusCode === 200) {
        self.getAlldiscounts(self.state.activeTab);
        self.closeModal();
      } else {
        self.setState({ isError: true, screenErrMsg: (nextProps.subscriptionChanged['data'] && nextProps.subscriptionChanged['data']['statusDescription']) ? nextProps.subscriptionChanged['data']['statusDescription'] : Constants.SOMETHING_WRONG });

        setTimeout(() => {
          self.onErrDismiss();
        }, 3000);

        self.getAlldiscounts(self.state.activeTab);
        self.closeModal();
      }
    }


    if (nextProps.couponUsers) {
      if (nextProps.couponUsers.statusCode === 200) {
        if (self.state.activeTab === Constants.PROMOTIONAL || self.state.activeTab === Constants.SCHOOL_AFFILIATES) {
          self.setState({
            userList: nextProps.couponUsers['result'],
            showPromoUsers: true
          })
        }



        if (self.state.activeTab === Constants.AFFILATED) {
          self.setState({
            userList: nextProps.couponUsers['result'],
            screenErrMsg: "",
            isError: false,
            showAffliatedUsers: true
          })
        }


      } else {
        self.setState({ isError: true, screenErrMsg: (nextProps.couponUsers['data'] && nextProps.couponUsers['data']['statusDescription']) ? nextProps.couponUsers['data']['statusDescription'] : Constants.SOMETHING_WRONG });

        setTimeout(() => {
          self.onErrDismiss();
        }, 3000);
        self.closeModal();
      }
    }


    if (nextProps.paymentAdded) {
      if (nextProps.paymentAdded.statusCode === 200) {
        self.setState({ screenErrMsg: "", isError: false, selectedRow: {} })
        self.getAlldiscounts(this.state.activeTab)
        this.closeModal();
      } else {
        self.setState({ isError: true, screenErrMsg: (nextProps.paymentAdded['data'] && nextProps.paymentAdded['data']['statusDescription']) ? nextProps.paymentAdded['data']['statusDescription'] : Constants.SOMETHING_WRONG });
      }
    }


    Loader(nextProps.requestProcess);
  }



  getAlldiscounts(activeTab) {
    this.props._getAlldiscounts({ discountType: activeTab });
  }

  deleteDiscountModal(rowData) {
    this.setState({
      openDeleteDiscountModal: true,
      selectedRow: rowData
    })
  }



  addPromotionalDiscountModal(rowData) {
    this.setState({
      openAddPromotionalDiscountModal: true
    })
  }


  addUserSpecificModal(rowData) {
    this.setState({
      userSpecificDiscountPopupError: "",
      openUserSpecificDiscountModal: true
    })
  }


  addAffiliatedDiscountModal(rowData) {
    this.setState({
      openAddAffiliatedDiscountModal: true
    })
  }


  addFreeDiscountModal(rowData) {
    this.setState({
      openAddFreeDiscountModal: true
    })
  }


  addSocialDiscountModal(rowData) {
    this.setState({
      openAddSocialDiscountModal: true
    })
  }

  closeModal() {
    this.setState({ openDeleteDiscountModal: false, openAddPromotionalDiscountModal: false, selectedRow: {}, openAddFreeDiscountModal: false, openAddSocialDiscountModal: false, openAddAffiliatedDiscountModal: false, changeSubscriptionModal: false, showPromoUsers: false, showAffliatedUsers: false, showSocialUsers: false, openUserSpecificDiscountModal: false });
  }




  handleChange(event) {
    let target = event.target;
    let name = target.name;
    let value;


    if (target.type === 'checkbox') {
      value = (this.state[name]) ? false : true
    } else {
      value = target.value;
    }
    this.setState({ [name]: value });
  }

  changeSubscription(event, rowData) {
    let subsObj = {
      featureId: rowData['_id'],
      subscription: (event.target.checked) ? Constants.PAID : Constants.FREE,
    }
    // calling api for change subscription
    this.props._updateFeature(subsObj);
  }


  deleteDiscount(event) {
    this.props._deleteDiscount({ discountId: this.state.selectedRow._id });
    this.setState({ selectedRow: "" });
    event.preventDefault();
  }


  addDiscount(discountObj) {
    this.props._addDiscount(discountObj);
    this.setState({ addPromotionalDiscountPopupError: "" });
  }

  setCurrentTab(tabName) {
    this.setState({ activeTab: tabName });
    this.getAlldiscounts(tabName);
  }

  changeUserSubscription(rowData) {
    this.setState({
      selectedParent: rowData,
      changeSubscriptionModal: true,
    })
  }


  addCommissionPayment(obj) {
    this.props._addCommissionPayment(obj)
  }


  confirmChangeSubscription() {
    let params = {
      parentId: this.state.selectedParent._id
    }
    this.props._changeUserSubscription(params)
  }

  onErrDismiss() {
    this.setState({ isError: false, screenErrMsg: "" });
  }

  setGridHeaders(headingData) {
    let gridHeadings = [];
    gridHeadings.push(<th key={headingData[0]['displayName']}>#</th>)
    _.each(headingData, function (value, index) {
      {
        gridHeadings.push(<th key={index}>{value['displayName']}</th>)
      };

    })

    return (<tr className="table-head">{gridHeadings}</tr>)
  }


  setPromotionaGridData(data) {
    let self = this;
    let gridData = [];

    if (data.length) {
      _.each(data, function (value, index) {
        {
          gridData.push(
            <tr key={index}>
              <th scope="row">{index + 1}</th>
              <td>{value['couponCode']}</td>
              <td>{value['discount']}</td>
              <td>{value['description']}</td>
              <td>{value['startOn']}</td>
              <td>{value['expiredOn']}</td>
              <td>{value['email']}</td>
              <td>
                {(value['totalUsers'] === 0) ? <Button color="primary" disabled={true}> Not yet used</Button>
                  :
                  <Button color="primary" onClick={(event) => { self.getCouponUsers(value) }}>{value['totalUsers']} Users applied</Button>}
              </td>
              <td>
                {
                  <Nav>
                    <NavItem>
                      {value.status && <NavLink href="#" disabled={!value.status} onClick={(e) => { self.deleteDiscountModal(value); e.preventDefault(); }}><FontAwesomeIcon icon={faTrashAlt} size='lg' /> </NavLink>}
                      {!value.status && <NavLink href="#" disabled={!value.status} onClick={(e) => { self.deleteDiscountModal(value); e.preventDefault(); }}><FontAwesomeIcon icon={faTrashAlt} size='lg' /> </NavLink>}
                    </NavItem>
                  </Nav>
                }
              </td>
            </tr>
          )
        };
      })
    } else {
      gridData.push(<tr key={this.discountsPromotionalColumns.length}><td className="no-data-found" colSpan={this.discountsPromotionalColumns.length + 1}>No Records Found</td></tr>)
    }

    return gridData;
  }


  setAffiliatedGridData(data) {
    let self = this;
    let gridData = [];
    _.each(data, function (value, index) {
      {
        gridData.push(
          <tr key={index}>
            <th scope="row">{index + 1}</th>
            <td>{value['couponCode']}</td>
            <td>{value['discount']}</td>
            <td>{value['commissionPercentage']}</td>
            <td>{value['description']}</td>
            <td>{value['startOn']}</td>
            <td>{value['expiredOn']}</td>
            <td>{value['affliatedEmail']}</td>
            <td>
              {(value['totalUsers'] === 0) ? <Button color="primary" disabled={true}> Not yet used</Button>
                :
                <Button color="primary" onClick={(event) => { self.getCouponUsers(value) }}>{value['totalUsers']} Users applied</Button>}
            </td>
            <td>
              {
                <Nav>
                  <NavItem>
                    {value.status && <NavLink href="#" disabled={!value.status} onClick={(e) => { self.deleteDiscountModal(value); e.preventDefault(); }}><FontAwesomeIcon icon={faTrashAlt} size='lg' /> </NavLink>}
                    {!value.status && <NavLink href="#" disabled={!value.status} onClick={(e) => { self.deleteDiscountModal(value); e.preventDefault(); }}><FontAwesomeIcon icon={faTrashAlt} size='lg' /> </NavLink>}
                  </NavItem>
                </Nav>
              }
            </td>
          </tr>
        )
      };
    })
    return gridData;
  }


  setFreeGridData(data) {
    let self = this;
    let gridData = [];
    _.each(data, function (value, index) {
      {
        gridData.push(
          <tr key={index}>
            <th scope="row">{index + 1}</th>
            <td>{value['couponCode']}</td>
            <td>{value['discount']}</td>
            <td>{value['description']}</td>
            <td>{value['startOn']}</td>
            <td>{value['expiredOn']}</td>
            <td>{value['email']}</td>

            <td>
              {
                <div>
                  <Nav>
                    <NavItem>
                      {(value.parents && value.parents._id) ? <Button color="primary" title="Change User Subscription" href="#" onClick={(e) => { self.changeUserSubscription(value.parents); e.preventDefault(); }}>{(value.parents.subscription === Constants.FREE) ? "Convert to paid user" : "Convert to free user"}</Button> : <Button color="primary" title="Change User Subscription" href="#" onClick={(e) => { e.preventDefault(); }} disabled>Not used yet</Button>}
                    </NavItem>

                  </Nav>
                </div>
              }
            </td>

            <td>
              {
                <Nav>
                  <NavItem>
                    {value.status && <NavLink href="#" disabled={!value.status} onClick={(e) => { self.deleteDiscountModal(value); e.preventDefault(); }}><FontAwesomeIcon icon={faTrashAlt} size='lg' /> </NavLink>}
                    {!value.status && <NavLink href="#" disabled={!value.status} onClick={(e) => { self.deleteDiscountModal(value); e.preventDefault(); }}><FontAwesomeIcon icon={faTrashAlt} size='lg' /> </NavLink>}
                  </NavItem>
                </Nav>
              }
            </td>


          </tr>
        )
      };
    })
    return gridData;
  }

  fallbackCopyTextToClipboard(text) {
    var textArea = document.createElement("textarea");
    textArea.value = text;
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
      var successful = document.execCommand('copy');
      var msg = successful ? 'successful' : 'unsuccessful';
      console.log('Fallback: Copying text command was ' + msg);
    } catch (err) {
      console.error('Fallback: Oops, unable to copy', err);
    }

    document.body.removeChild(textArea);
  }

  copySocialUrl(e, obj) {
    if (!navigator.clipboard) {
      this.fallbackCopyTextToClipboard(obj.inviteUrl);
      return;
    }
    navigator.clipboard.writeText(obj.inviteUrl).then(function () {
      console.log('Async: Copying to clipboard was successful!');


    }, function (err) {
      console.error('Async: Could not copy text: ', err);
    });
    this.setState({ copyText: "Copied!" })
  }

  setCopyTextToInitial() {
    let self = this
    setTimeout(() => {
      self.setState({ copyText: "Copy to clipboard" })
    }, 100)
  }


  setSocialGridData(data) {
    let self = this;
    let gridData = [];
    _.each(data, function (value, index) {
      {
        gridData.push(
          <tr key={index}>
            <th scope="row">{index + 1}</th>
            <td>{value['inviteUrl']}</td>
            <td>{value['couponCode']}</td>
            <td>{value['affliatedEmail']}</td>

            <td>
              {
                <div>
                  <Nav>
                    <NavItem>
                      {(value.totalSocialLinkedUsers) ? <Button color="primary" title="Get user details" href="#" onClick={(e) => { self.showAllSocialUsers(value); e.preventDefault(); }}>{(value.totalSocialLinkedUsers > 1) ? value.totalSocialLinkedUsers + " People Used" : value.totalSocialLinkedUsers + " Person Used"}</Button> : <Button color="primary" title="Change User Subscription" href="#" onClick={(e) => { e.preventDefault(); }} disabled>Not used yet</Button>}
                    </NavItem>

                  </Nav>
                </div>
              }
            </td>

            <td>
              {
                <Nav>
                  <NavItem>
                    {value.status && <NavLink title="delete discount" href="#" disabled={!value.status} onClick={(e) => { self.deleteDiscountModal(value); e.preventDefault(); }}><FontAwesomeIcon icon={faTrashAlt} size='lg' /> </NavLink>}
                    {!value.status && <NavLink href="#" disabled={!value.status} onClick={(e) => { self.deleteDiscountModal(value); e.preventDefault(); }}><FontAwesomeIcon icon={faTrashAlt} size='lg' /> </NavLink>}
                  </NavItem>

                  <NavItem>
                    <NavLink href="#" className="tooltip-custom" onMouseOut={() => self.setCopyTextToInitial()} onClick={(e) => { self.copySocialUrl(e, value); e.preventDefault(); }}><FontAwesomeIcon icon={faCopy} size='lg' /> <span className="tooltiptext" id="myTooltip">{self.state.copyText}</span></NavLink>
                  </NavItem>
                </Nav>
              }
            </td>


          </tr>
        )
      };
    })
    return gridData;
  }

  setUserSpecificDiscountGridData(data) {
    let self = this;
    let gridData = [];
    _.each(data, function (value, index) {
      {
        gridData.push(
          <tr key={index}>
            <th scope="row">{index + 1}</th>
            <td>{value['email']}</td>
            <td>{value['discount']}</td>
            <td>{value['description']}</td>
            <td>{value['totalUsers'] ? <FontAwesomeIcon icon={faCheck} size='lg' /> : <FontAwesomeIcon icon={faTimes} size='lg' />}</td>

            <td>
              {
                <Nav>
                  <NavItem>
                    {value.status && <NavLink title="delete discount" href="#" disabled={!value.status} onClick={(e) => { self.deleteDiscountModal(value); e.preventDefault(); }}><FontAwesomeIcon icon={faTrashAlt} size='lg' /> </NavLink>}
                    {!value.status && <NavLink href="#" disabled={!value.status} onClick={(e) => { self.deleteDiscountModal(value); e.preventDefault(); }}><FontAwesomeIcon icon={faTrashAlt} size='lg' /> </NavLink>}
                  </NavItem>

                </Nav>
              }
            </td>

          </tr>
        )
      };
    })
    return gridData;
  }

  setPagination(totalRecords, rowsPerPage) {
    // let self = this;
    let pages = [];

    let pagesCount = (totalRecords / rowsPerPage < 0) ? 0 : totalRecords / rowsPerPage;

    for (let i = 1; i <= pagesCount; i++) {
      pages.push(<PaginationItem><PaginationLink href="#">{i}</PaginationLink></PaginationItem>)
    }

    // return ({pages}); 
    return (
      <Pagination className="float-right" aria-label="Page navigation example">
        <PaginationItem>
          <PaginationLink first href="#" />
        </PaginationItem>
        <PaginationItem>
          <PaginationLink previous href="#" />
        </PaginationItem>
        {pages}
        <PaginationItem>
          <PaginationLink next href="#" />
        </PaginationItem>
        <PaginationItem>
          <PaginationLink last href="#" />
        </PaginationItem>
      </Pagination>
    );
  }


  showCouponUsers(rowData) {
    this.setState({
      userList: rowData,
      showPromoUsers: true
    })
  }


  showAllSocialUsers(rowData) {
    this.setState({
      userList: rowData,
      showSocialUsers: true
    })
  }


  getCouponUsers(rowData) {
    let params = {
      discountId: rowData._id
    };
    this.setState({ selectedRow: rowData });
    this.props._getCouponUsers(params)
  }





  render() {
    //let self = this;
    let { discounts, openDeleteDiscountModal, selectedRow, openAddPromotionalDiscountModal, addPromotionalDiscountPopupError, updatediscountPopupError, activeTab, openAddFreeDiscountModal, openAddSocialDiscountModal, addFreeDiscountPopupError, openAddAffiliatedDiscountModal, addAffiliatedDiscountPopupError, appstoreDifferences, changeSubscriptionModal, selectedParent, screenErrMsg, isError, showPromoUsers, userList, showAffliatedUsers, addSocialDiscountPopupError, showSocialUsers, openUserSpecificDiscountModal, userSpecificDiscountPopupError } = this.state;
    let totalRecordsLabel = '##TOTAL_RECORDS## ' + (discounts && discounts.length > 1) ? 'Discounts' : 'Discount';


    return (
      <div className="content">
        <Row className="pb-4">
          <Col lg={12} sm={12}>
            <Row className="pb-4">
              <Col lg={12} sm={12}>
                <h3>Discount</h3>
              </Col>
            </Row>
          </Col>
        </Row>

        <Alert color="danger" isOpen={isError} toggle={() => { this.onErrDismiss() }}>
          {screenErrMsg}
        </Alert>

        <Nav tabs>
          <NavItem>
            <NavLink
              className={classnames({ active: this.state.activeTab === Constants.PROMOTIONAL })}
              onClick={() => { this.setCurrentTab(Constants.PROMOTIONAL); }}>
              {Constants.PROMOTIONAL}
            </NavLink>
          </NavItem>

          <NavItem>
            <NavLink
              className={classnames({ active: this.state.activeTab === Constants.SCHOOL_AFFILIATES })}
              onClick={() => { this.setCurrentTab(Constants.SCHOOL_AFFILIATES); }}>
              {Constants.SCHOOL_AFFILIATES.replace('_', ' ')}
            </NavLink>
          </NavItem>

          

          <NavItem>
            <NavLink
              className={classnames({ active: this.state.activeTab === Constants.AFFILATED })}
              onClick={() => { this.setCurrentTab(Constants.AFFILATED); }}>
              AFFILIATED
            </NavLink>
          </NavItem>

          <NavItem>
            <NavLink
              className={classnames({ active: this.state.activeTab === Constants.FREE_CODE })}
              onClick={() => { this.setCurrentTab(Constants.FREE_CODE); }}>
              {Constants.FREE_CODE}
            </NavLink>
          </NavItem>


          <NavItem>
            <NavLink
              className={classnames({ active: this.state.activeTab === Constants.SOCIAL_INVITE })}
              onClick={() => { this.setCurrentTab(Constants.SOCIAL_INVITE); }}>
              {Constants.SOCIAL_INVITE.replace('_', ' ')}
            </NavLink>
          </NavItem>


          <NavItem>
            <NavLink
              className={classnames({ active: this.state.activeTab === Constants.USER_SPECIFIC })}
              onClick={() => { this.setCurrentTab(Constants.USER_SPECIFIC); }}>
              {Constants.USER_SPECIFIC.replace('_', ' ')}
            </NavLink>
          </NavItem>
        </Nav>


        <TabContent activeTab={activeTab}>
        

          <TabPane tabId={Constants.PROMOTIONAL}>
            <Row className="pb-4">
              <Col lg={12} sm={12}>
                <Row className="pb-4">
                  <Col lg={12} sm={12} className="text-right">
                    {
                      <button className="btn btn-primary" color="link" onClick={() => this.addPromotionalDiscountModal()}>Add Promotional Discount</button>
                    }
                  </Col>
                </Row>
                {openAddPromotionalDiscountModal &&
                  <AddPromotionalDiscount {...this.props}
                    modelTitle={'Add Promotional Coupons'}
                    openAddPromotionalDiscountModal={openAddPromotionalDiscountModal}
                    closeModal={this.closeModal}
                    addDiscount={(discountsObj) => { this.addDiscount(discountsObj) }}
                    activeTab={activeTab}
                    appstoreDifferences={appstoreDifferences}
                    addPromotionalDiscountPopupError={addPromotionalDiscountPopupError} />}


                <div className="dataTable-wrapper pillarList_grid">
                  {/*<DataGrid
                      columns={this.discountsPromotionalColumns}
                      options={discountsOptions}
                      rows={ discounts }
                    />*/}


                  <Table responsive hover>
                    <thead>
                      {this.setGridHeaders(this.discountsPromotionalColumns)}
                    </thead>
                    <tbody>
                      {this.setPromotionaGridData(discounts)}
                    </tbody>
                  </Table>
                  <p className="clearfix">Total Records : {discounts.length}
                    {/*this.setPagination(discounts.length, discounts.length)*/}
                  </p>

                </div>
              </Col>
            </Row>
          </TabPane>

          <TabPane tabId={Constants.SCHOOL_AFFILIATES}>
            <Row className="pb-4">
              <Col lg={12} sm={12}>
                <Row className="pb-4">
                  <Col lg={12} sm={12} className="text-right">
                    {
                      <button className="btn btn-primary" color="link" onClick={() => this.addPromotionalDiscountModal()}>Add School Affiliate Discount</button>
                    }
                  </Col>
                </Row>
                {openAddPromotionalDiscountModal &&
                  <AddPromotionalDiscount {...this.props}
                    modelTitle={'Add School Affiliate Coupons'}
                    openAddPromotionalDiscountModal={openAddPromotionalDiscountModal}
                    closeModal={this.closeModal}
                    addDiscount={(discountsObj) => { this.addDiscount(discountsObj) }}
                    activeTab={activeTab}
                    appstoreDifferences={appstoreDifferences}
                    addPromotionalDiscountPopupError={addPromotionalDiscountPopupError} />}


                <div className="dataTable-wrapper pillarList_grid">
                  {/*<DataGrid
                      columns={this.discountsPromotionalColumns}
                      options={discountsOptions}
                      rows={ discounts }
                    />*/}


                  <Table responsive hover>
                    <thead>
                      {this.setGridHeaders(this.discountsPromotionalColumns)}
                    </thead>
                    <tbody>
                      {this.setPromotionaGridData(discounts)}
                    </tbody>
                  </Table>
                  <p className="clearfix">Total Records : {discounts.length}
                    {/*this.setPagination(discounts.length, discounts.length)*/}
                  </p>

                </div>
              </Col>
            </Row>
          </TabPane>



          <TabPane tabId={Constants.AFFILATED}>
            <Row className="pb-4">
              <Col lg={12} sm={12}>
                <Row className="pb-4">
                  <Col lg={12} sm={12} className="text-right">
                    {
                      <button className="btn btn-primary" color="link" onClick={() => this.addAffiliatedDiscountModal()}>Add Affiliated Discount</button>
                    }
                  </Col>
                </Row>
                {openAddAffiliatedDiscountModal &&
                  <AddAffiliatedDiscount {...this.props}
                    openAddAffiliatedDiscountModal={openAddAffiliatedDiscountModal}
                    closeModal={this.closeModal}
                    addDiscount={(discountsObj) => { this.addDiscount(discountsObj) }}
                    activeTab={activeTab}
                    appstoreDifferences={appstoreDifferences}
                    addAffiliatedDiscountPopupError={addAffiliatedDiscountPopupError} />}


                <div className="dataTable-wrapper pillarList_grid">
                  {/*<DataGrid
                      columns={this.discountsPromotionalColumns}
                      options={discountsOptions}
                      rows={ discounts }
                    />*/}

                  <Table responsive hover>
                    <thead>
                      {this.setGridHeaders(this.discountsAffliatedColumns)}
                    </thead>
                    <tbody>
                      {this.setAffiliatedGridData(discounts)}
                    </tbody>
                  </Table>
                  <p className="clearfix">Total Records : {discounts.length}
                    {/*this.setPagination(discounts.length, discounts.length)*/}
                  </p>


                </div>
              </Col>
            </Row>
          </TabPane>

          <TabPane tabId={Constants.FREE_CODE}>
            <Row className="pb-4">
              <Col lg={12} sm={12}>
                <Row className="pb-4">
                  <Col lg={12} sm={12} className="text-right">
                    {
                      <button className="btn btn-primary" color="link" onClick={() => this.addFreeDiscountModal()}>Add Free Discount</button>
                    }
                  </Col>
                </Row>
                {openAddFreeDiscountModal &&
                  <AddFreeDiscount {...this.props}
                    openAddFreeDiscountModal={openAddFreeDiscountModal}
                    closeModal={this.closeModal}
                    addDiscount={(discountsObj) => { this.addDiscount(discountsObj) }}
                    activeTab={activeTab}
                    appstoreDifferences={appstoreDifferences}
                    addFreeDiscountPopupError={addFreeDiscountPopupError} />}


                <div className="dataTable-wrapper pillarList_grid">
                  {/*<DataGrid
                      columns={this.freeDiscountsColumns}
                      options={freeDiscountOptions}
                      rows={ discounts }
                    />*/}


                  <Table responsive hover>
                    <thead>
                      {this.setGridHeaders(this.freeDiscountsColumns)}
                    </thead>
                    <tbody>
                      {this.setFreeGridData(discounts)}
                    </tbody>
                  </Table>
                  <p className="clearfix">Total Records : {discounts.length}
                    {/*this.setPagination(discounts.length, discounts.length)*/}
                  </p>

                </div>
              </Col>
            </Row>

            <Modal isOpen={changeSubscriptionModal}>
              <ModalHeader toggle={() => { this.closeModal(false) }}>Change User Subscription</ModalHeader>
              <ModalBody>
                Are you sure you want to change {(selectedParent && selectedParent.email) ? selectedParent.email + "'s" : " current user's "} subsciption to {(selectedParent.subscription === Constants.FREE ? 'Paid' : 'Free')}
              </ModalBody>
              <ModalFooter className="justify-content-left">
                <Button type="button" size="sm" color="primary" onClick={() => { this.confirmChangeSubscription() }}>Yes</Button>{' '}
                <Button type="reset" size="sm" color="danger" onClick={() => { this.closeModal() }}>No</Button>
              </ModalFooter>
            </Modal>

          </TabPane>


          <TabPane tabId={Constants.SOCIAL_INVITE}>
            <Row className="pb-4">
              <Col lg={12} sm={12}>
                <Row className="pb-4">
                  <Col lg={12} sm={12} className="text-right">
                    {
                      <button className="btn btn-primary" color="link" onClick={() => this.addSocialDiscountModal()}>Add Social Discount</button>
                    }
                  </Col>
                </Row>
                {openAddSocialDiscountModal &&
                  <AddSocialDiscount {...this.props}
                    openAddSocialDiscountModal={openAddSocialDiscountModal}
                    closeModal={this.closeModal}
                    addDiscount={(discountsObj) => { this.addDiscount(discountsObj) }}
                    activeTab={activeTab}
                    appstoreDifferences={appstoreDifferences}
                    addSocialDiscountPopupError={addSocialDiscountPopupError} />}


                <div className="dataTable-wrapper pillarList_grid">
                  {/*<DataGrid
                      columns={this.freeDiscountsColumns}
                      options={freeDiscountOptions}
                      rows={ discounts }
                    />*/}


                  <Table responsive hover>
                    <thead>
                      {this.setGridHeaders(this.socialDiscountsColumns)}
                    </thead>
                    <tbody>
                      {this.setSocialGridData(discounts)}
                    </tbody>
                  </Table>
                  <p className="clearfix">Total Records : {discounts.length}
                    {/*this.setPagination(discounts.length, discounts.length)*/}
                  </p>

                </div>
              </Col>
            </Row>

            <Modal isOpen={changeSubscriptionModal}>
              <ModalHeader toggle={() => { this.closeModal(false) }}>Change User Subscription</ModalHeader>
              <ModalBody>
                Are you sure you want to change {(selectedParent && selectedParent.email) ? selectedParent.email + "'s" : " current user's "} subsciption to {(selectedParent.subscription === Constants.FREE ? 'Paid' : 'Free')}
              </ModalBody>
              <ModalFooter className="justify-content-left">
                <Button type="button" size="sm" color="primary" onClick={() => { this.confirmChangeSubscription() }}>Yes</Button>{' '}
                <Button type="reset" size="sm" color="danger" onClick={() => { this.closeModal() }}>No</Button>
              </ModalFooter>
            </Modal>

          </TabPane>



          <TabPane tabId={Constants.USER_SPECIFIC}>
            <Row className="pb-4">
              <Col lg={12} sm={12}>
                <Row className="pb-4">
                  <Col lg={12} sm={12} className="text-right">
                    {
                      <button className="btn btn-primary" color="link" onClick={() => this.addUserSpecificModal()}>Add User Specific Discount</button>
                    }
                  </Col>
                </Row>
                {openUserSpecificDiscountModal &&
                  <AddUserSpecificDiscount {...this.props}
                    openUserSpecificDiscountModal={openUserSpecificDiscountModal}
                    closeModal={this.closeModal}
                    addDiscount={(discountsObj) => { this.addDiscount(discountsObj) }}
                    activeTab={activeTab}
                    appstoreDifferences={appstoreDifferences}
                    userSpecificDiscountPopupError={userSpecificDiscountPopupError} />}


                <div className="dataTable-wrapper pillarList_grid">

                  <Table responsive hover>
                    <thead>
                      {this.setGridHeaders(this.userSpecificDiscountsColumns)}
                    </thead>
                    <tbody>
                      {this.setUserSpecificDiscountGridData(discounts)}
                    </tbody>
                  </Table>
                  <p className="clearfix">Total Records : {discounts.length}
                    {/*this.setPagination(discounts.length, discounts.length)*/}
                  </p>

                </div>
              </Col>
            </Row>

            <Modal isOpen={changeSubscriptionModal}>
              <ModalHeader toggle={() => { this.closeModal(false) }}>Change User Subscription</ModalHeader>
              <ModalBody>
                Are you sure you want to change {(selectedParent && selectedParent.email) ? selectedParent.email + "'s" : " current user's "} subsciption to {(selectedParent.subscription === Constants.FREE ? 'Paid' : 'Free')}
              </ModalBody>
              <ModalFooter className="justify-content-left">
                <Button type="button" size="sm" color="primary" onClick={() => { this.confirmChangeSubscription() }}>Yes</Button>{' '}
                <Button type="reset" size="sm" color="danger" onClick={() => { this.closeModal() }}>No</Button>
              </ModalFooter>
            </Modal>

          </TabPane>


        </TabContent>

        <div></div>
        <Modal isOpen={openDeleteDiscountModal}>
          <ModalHeader toggle={() => { this.closeModal(false) }}>Delete discount</ModalHeader>
          <ModalBody>
            <Row>
              <Col xs="12">
                <p>
                  This Discount will only delete if it is not associated with any user, Otherwise it will be soft deleted and maintained in out records.
                    </p>
                <p>
                  Are you sure you want to delete this discount coupon?
                    </p>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter className="justify-content-left">
            <Button type="button" onClick={(event) => { this.deleteDiscount(event) }} size="sm" color="primary">Delete</Button>{' '}
            <Button type="reset" size="sm" color="danger" onClick={() => { this.closeModal() }}>Cancel</Button>
          </ModalFooter>
        </Modal>

        {showPromoUsers && <ShowAllPromoUsers visible={showPromoUsers} selectedRow={selectedRow} closeModal={(event) => this.closeModal(event)} userList={userList} activeTab={activeTab} />}



        {showSocialUsers && <ShowAllSocialUsers visible={showSocialUsers} selectedRow={selectedRow} closeModal={(event) => this.closeModal(event)} userList={userList} activeTab={activeTab} />}


        {showAffliatedUsers && <ShowAllAffliatedUsers visible={showAffliatedUsers} selectedRow={selectedRow} closeModal={(event) => this.closeModal(event)} userList={userList} activeTab={activeTab} addCommissionPayment={(obj) => this.addCommissionPayment(obj)} screenErrMsg={screenErrMsg} />}
      </div>


    );
  }
}

export default Discount;
